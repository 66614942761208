html {
  -webkit-font-smoothing: antialiased;
  font-size: 1.2rem;
  scroll-behavior: auto;
}

/*-----------------*/
/* Customize style */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  /* Black translucent layer */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 90%;
  text-align: center;
  position: relative;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.3);
  /* Adds a subtle shadow */
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

/*------------------------------------------------------------------*/
/*  IE10 in Windows 8 and Windows Phone 8 Bug fix
/*-----------------------------------------------------------------*/

@-webkit-viewport {
  width: device-width;
}

@-moz-viewport {
  width: device-width;
}

@-ms-viewport {
  width: device-width;
}

@-o-viewport {
  width: device-width;
}

@viewport {
  width: device-width;
}

/*------------------------------------------*/
/*  PAGE CONTENT
/*------------------------------------------*/

#page {
  overflow: hidden;
}

/*------------------------------------------*/
/*  SPACING & INDENTS
/*------------------------------------------*/

.wide-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.wide-90 {
  padding-top: 100px;
  padding-bottom: 90px;
}

.wide-80 {
  padding-top: 100px;
  padding-bottom: 80px;
}

.wide-70 {
  padding-top: 100px;
  padding-bottom: 70px;
}

.wide-60 {
  padding-top: 100px;
  padding-bottom: 60px;
}

.wide-50 {
  padding-top: 100px;
  padding-bottom: 50px;
}

.wide-40 {
  padding-top: 100px;
  padding-bottom: 40px;
}

.wide-30 {
  padding-top: 100px;
  padding-bottom: 30px;
}

.wide-20 {
  padding-top: 100px;
  padding-bottom: 20px;
}

/*------------------------------------------*/
/*  Margin Top
/*------------------------------------------*/

.mt-100 {
  margin-top: 100px;
}

.mt-95 {
  margin-top: 95px;
}

.mt-90 {
  margin-top: 90px;
}

.mt-85 {
  margin-top: 85px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-75 {
  margin-top: 75px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-65 {
  margin-top: 65px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-55 {
  margin-top: 55px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-45 {
  margin-top: 45px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-35 {
  margin-top: 35px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-5 {
  margin-top: 5px;
}

/*------------------------------------------*/
/*  Margin Bottom
/*------------------------------------------*/

.mb-100 {
  margin-bottom: 100px;
}

.mb-95 {
  margin-bottom: 95px;
}

.mb-90 {
  margin-bottom: 90px;
}

.mb-85 {
  margin-bottom: 85px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-75 {
  margin-bottom: 75px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mb-65 {
  margin-bottom: 65px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-55 {
  margin-bottom: 55px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-45 {
  margin-bottom: 45px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-0 {
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  BACKGROUND SETTINGS
/*------------------------------------------*/

.bg-fixed,
.bg-scroll {
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.bg-scroll {
  background-attachment: fixed !important;
}

/*------------------------------------------*/
/*  Background Colors
/*------------------------------------------*/
/*
background-color: #f3f6f7;*/

.bg-lightdark {
  background-color: #283034;
}

.bg-dark {
  background-color: #1d1e29 !important;
}

.bg-lightgrey {
  background-color: #f8f9fb;
}

.bg-whitesmoke {
  background-color: #f2f5f6;
}

.bg-carrot {
  background-color: #d05a24;
}

.bg-blue {
  background-color: #3284bf;
}

.bg-darkblue {
  background-color: #253993;
}

.bg-skyblue {
  background-color: #1ba1f0;
}

.bg-green {
  background-color: #20ab5c;
}

.bg-lightgreen {
  background-color: #8dd17e;
}

.bg-red {
  background-color: #ff6d33;
}

.bg-rose {
  background-color: #ff1f59;
}

.bg-olive {
  background-color: #7ba323;
}

.bg-orange {
  background-color: #ff4500;
}

.bg-teal {
  background-color: #109d87;
}

.bg-yellow {
  background-color: #ffb605;
}

.bg-violet {
  background-color: #8a8cbd;
}

/*------------------------------------------*/
/*  Background Images for Sections
/*------------------------------------------*/

.bg-01,
.bg-02,
.bg-03,
.bg-04,
.bg-map {
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed !important;
}


.bg-map {
  background-image: url(../../public/images/bg-map.png);
}


.bg-03 {
  background-color: #333333;

}



/*------------------------------------------*/
/*   BORDER SETTINGS FOR DEMOS
/*------------------------------------------*/

.b-top {
  border-top: 1px solid #ddd;
}

.b-bottom {
  border-bottom: 1px solid #ddd;
}

.b-left {
  border-left: 1px solid #ddd;
}

.b-right {
  border-right: 1px solid #ddd;
}

/* ==========================================================================
  02. TYPOGRAPHY
  =========================================================================== */

/*------------------------------------------*/
/*  HEADERS
/*------------------------------------------*/

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
  letter-spacing: -0.5px;
}

/* Header H5 */
h5.h5-xs {
  font-size: 1.125rem;
}

/* 18px */
h5.h5-sm {
  font-size: 1.1875rem;
}

/* 19px */
h5.h5-md {
  font-size: 1.25rem;
}

/* 20px */
h5.h5-lg {
  font-size: 1.375rem;
}

/* 22px */
h5.h5-xl {
  font-size: 1.5rem;
}

/* 24px */

/* Header H4 */
h4.h4-xs {
  font-size: 1.625rem;
}

/* 26px */
h4.h4-sm {
  font-size: 1.75rem;
}

/* 28px */
h4.h4-md {
  font-size: 1.875rem;
}

/* 30px */
h4.h4-lg {
  font-size: 2rem;
}

/* 32px */
h4.h4-xl {
  font-size: 2.125rem;
}

/* 34px */

/* Header H3 */
h3.h3-xs {
  font-size: 2.375rem;
}

/* 38px */
h3.h3-sm {
  font-size: 2.5rem;
}

/* 40px */
h3.h3-md {
  font-size: 2.625rem;
}

/* 42px */
h3.h3-lg {
  font-size: 2.75rem;
}

/* 44px */
h3.h3-xl {
  font-size: 3rem;
}

/* 48px */

/* Header H2 */
h2.h2-xs {
  font-size: 3.25rem;
}

/* 52px */
h2.h2-sm {
  font-size: 3.375rem;
}

/* 54px */
h2.h2-md {
  font-size: 3.5rem;
}

/* 56px */
h2.h2-lg {
  font-size: 3.75rem;
}

/* 60px */
h2.h2-xl {
  font-size: 4rem;
}

/* 64px */
h2.h2-huge {
  font-size: 5rem;
}

/* 80px */

/*------------------------------------------*/
/*  PARAGRAPHS
/*------------------------------------------*/

p.p-sm {
  font-size: 0.95rem;
}

/* 15.2px */
p {
  font-size: 0.85rem;
  margin-top: 0.2rem;
  margin-bottom: 0.35rem;
}

/* 16.8px */
p.p-md {
  font-size: 1rem;
}

/* 22px */

/*------------------------------------------*/
/*  LINK SETTINGS
/*------------------------------------------*/

a {
  color: #333;
  text-decoration: none;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

a:hover {
  color: #666;
  text-decoration: none;
}

a:focus {
  outline: none;
  text-decoration: none;
}

/*------------------------------------------*/
/*  LISTS
/*------------------------------------------*/

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

/*------------------------------------------*/
/*  TEXT LIST
/*------------------------------------------*/

ul.txt-list {
  list-style: disc;
  margin-left: 15px;
}

ul.txt-list li,
ul.ico-list li {
  font-size: 0.85rem;
  margin-bottom: 7px;
}

ul.txt-list li:last-child,
ul.ico-list li:last-child {
  margin-bottom: 0;
}

ul.txt-list li span {
  font-weight: 700;
}

ul.ico-list li i {
  text-align: center;
  float: left;
  font-size: 0.85rem;
  margin-top: 6px;
  margin-right: 10px;
}

/*------------------------------------------*/
/*  BUTTON SETTINGS
/*------------------------------------------*/

.btn {
  background-color: transparent;
  color: #fff;
  font-size: 15px;
  line-height: 1;
  font-weight: 700;
  padding: 13px 26px;
  border: 2px solid transparent;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-transition: all 450ms ease-in-out;
  -moz-transition: all 450ms ease-in-out;
  -o-transition: all 450ms ease-in-out;
  -ms-transition: all 450ms ease-in-out;
  transition: all 450ms ease-in-out;
}



@-webkit-keyframes pulse-border {
  0% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}

@keyframes pulse-border {
  0% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}


/*------------------------------------------*/
/*   PNG ICONS SETTINGS
/*------------------------------------------*/

.img-150 {
  width: 150px;
  height: 150px;
}

.img-145 {
  width: 145px;
  height: 145px;
}

.img-140 {
  width: 140px;
  height: 140px;
}

.img-135 {
  width: 135px;
  height: 135px;
}

.img-130 {
  width: 130px;
  height: 130px;
}

.img-125 {
  width: 125px;
  height: 125px;
}

.img-120 {
  width: 120px;
  height: 120px;
}

.img-115 {
  width: 115px;
  height: 115px;
}

.img-110 {
  width: 110px;
  height: 110px;
}

.img-105 {
  width: 105px;
  height: 105px;
}

.img-100 {
  width: 100px;
  height: 100px;
}

.img-95 {
  width: 95px;
  height: 95px;
}

.img-90 {
  width: 90px;
  height: 90px;
}

.img-85 {
  width: 85px;
  height: 85px;
}

.img-80 {
  width: 80px;
  height: 80px;
}

.img-75 {
  width: 75px;
  height: 75px;
}

.img-70 {
  width: 70px;
  height: 70px;
}

.img-65 {
  width: 65px;
  height: 65px;
}

.img-60 {
  width: 60px;
  height: 60px;
}

.img-55 {
  width: 55px;
  height: 55px;
}

.img-50 {
  width: 50px;
  height: 50px;
}

.img-45 {
  width: 45px;
  height: 45px;
}

.img-40 {
  width: 40px;
  height: 40px;
}

.img-35 {
  width: 35px;
  height: 35px;
}

.img-30 {
  width: 30px;
  height: 30px;
}

/*------------------------------------------*/
/*  SECTION TITLE
/*------------------------------------------*/

.section-title {
  position: relative;
  padding-right: 48%;
}

.section-title.title-centered {
  text-align: center;
  padding: 0 20%;
}

.section-title h3 {
  margin-bottom: 12px;
}

.section-title h4 {
  margin-bottom: 8px;
}

.section-title p {
  color: #888;
  margin-bottom: 0;
}

.section-title.title-centered p {
  padding: 0 5%;
}


.title-btn {
  position: absolute;
  right: 15px;
  bottom: 5px;
}

/*------------------------------------------*/
/*  TEXT COLOR
/*------------------------------------------*/

.white-color,
.white-color h2,
.white-color h3,
.white-color h4,
.white-color h5,
.white-color h6,
.white-color p,
.white-color a,
.white-color li,
.white-color i,
.white-color span {
  color: #fff;
}

.grey-color,
.grey-color h2,
.grey-color h3,
.grey-color h4,
.grey-color h5,
.grey-color h6,
.grey-color p,
.grey-color a,
.grey-color li,
.grey-color i,
.grey-color span,
.white-color .grey-color {
  color: #757575;
}

.lightgrey-color,
.lightgrey-color h2,
.lightgrey-color h3,
.lightgrey-color h4,
.lightgrey-color h5,
.lightgrey-color h6,
.lightgrey-color p,
.lightgrey-color a,
.lightgrey-color li,
.lightgrey-color i,
.lightgrey-color span,
.white-color .lightgrey-color {
  color: #bbb;
}

.blue-color,
.blue-color h2,
.blue-color h3,
.blue-color h4,
.blue-color h5,
.blue-color h6,
.blue-color p,
.blue-color a,
.blue-color li,
.blue-color i,
.blue-color span,
.grey-color .blue-color,
.white-color .blue-color {
  color: #3284bf;
}

.skyblue-color,
.skyblue-color h2,
.skyblue-color h3,
.skyblue-color h4,
.skyblue-color h5,
.skyblue-color h6,
.skyblue-color p,
.skyblue-color a,
.skyblue-color li,
.skyblue-color i,
.skyblue-color span,
.grey-color .skyblue-color,
.white-color .skyblue-color {
  color: #1ba1f0;
}

.carrot-color,
.carrot-color h2,
.carrot-color h3,
.carrot-color h4,
.carrot-color h5,
.carrot-color h6,
.carrot-color p,
.carrot-color a,
.carrot-color li,
.carrot-color i,
.carrot-color span,
.grey-color .carrot-color,
.white-color .carrot-color {
  color: #d05a24;
}

.green-color,
.green-color h2,
.green-color h3,
.green-color h4,
.green-color h5,
.green-color h6,
.green-color p,
.green-color a,
.green-color li,
.green-color i,
.green-color span,
.grey-color .green-color,
.white-color .green-color {
  color: #20ab5c;
}

.lightgreen-color,
.lightgreen-color h2,
.lightgreen-color h3,
.lightgreen-color h4,
.lightgreen-color h5,
.lightgreen-color h6,
.lightgreen-color p,
.lightgreen-color a,
.lightgreen-color li,
.lightgreen-color i,
.lightgreen-color span,
.grey-color .lightgreen-color,
.white-color .lightgreen-color {
  color: #8dd17e;
}

.orange-color,
.orange-color h2,
.orange-color h3,
.orange-color h4,
.orange-color h5,
.orange-color h6,
.orange-color p,
.orange-color a,
.orange-color li,
.orange-color i,
.orange-color span,
.grey-color .orange-color,
.white-color .orange-color {
  color: #ff4500;
}

.red-color,
.red-color h2,
.red-color h3,
.red-color h4,
.red-color h5,
.red-color h6,
.red-color p,
.red-color a,
.red-color li,
.red-color i,
.red-color span,
.grey-color .red-color,
.white-color .red-color {
  color: #ff6d33;
}

.rose-color,
.rose-color h2,
.rose-color h3,
.rose-color h4,
.rose-color h5,
.rose-color h6,
.rose-color p,
.rose-color a,
.rose-color li,
.rose-color i,
.rose-olor span,
.grey-color .rose-color,
.white-color .rose-color {
  color: #ff1f59;
}

.teal-color,
.teal-color h2,
.teal-color h3,
.teal-color h4,
.teal-color h5,
.teal-color h6,
.teal-color p,
.teal-color a,
.teal-color li,
.teal-color i,
.teal-color span,
.grey-color .teal-color,
.white-color .teal-color {
  color: #109d87;
}

/*------------------------------------------*/
/*  TEXT BLOCK TYPOGRAPHY
/*------------------------------------------*/

.txt-block h3.h3-sm {
  margin-bottom: 25px;
}

.txt-block h5.h5-lg {
  margin-top: 5px;
  margin-bottom: 20px;
}

.txt-block .btn {
  margin-top: 20px;
}

/*------------------------------------------*/
/*  IMAGE BLOCK SETTINGS
/*------------------------------------------*/

.img-block {
  text-align: center;
}

/* ==========================================================================
  03. PRELOAD SPINNER
  =========================================================================== */

#loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999999;
}

#loading {
  background-color: #014d79;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 99999999;
  margin-top: 0px;
  top: 0px;
}

#loading-center {
  width: 100%;
  height: 100%;
  position: relative;
}

#loading-main {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 99999999;
  margin-top: 0px;
  top: 0px;
}

#loading-anim {
  width: 100%;
  height: 100%;
  position: relative;
}

.cssload-loading {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -20px;
  margin-left: -40px;
}

.cssload-container {
  display: block;
  margin: 47px auto;
  width: 95px;
}

.cssload-loading i {
  width: 50px;
  height: 50px;
  display: inline-block;
  border-radius: 50%;
  background: rgb(231, 141, 7);
}

.cssload-loading i:first-child {
  opacity: 0;
  animation: cssload-loading-ani2 0.475s linear infinite;
  -o-animation: cssload-loading-ani2 0.475s linear infinite;
  -ms-animation: cssload-loading-ani2 0.475s linear infinite;
  -webkit-animation: cssload-loading-ani2 0.475s linear infinite;
  -moz-animation: cssload-loading-ani2 0.475s linear infinite;
  transform: translate(-219px);
  -o-transform: translate(-219px);
  -ms-transform: translate(-219px);
  -webkit-transform: translate(-219px);
  -moz-transform: translate(-219px);
}

.cssload-loading i:nth-child(2),
.cssload-loading i:nth-child(3) {
  animation: cssload-loading-ani3 0.475s linear infinite;
  -o-animation: cssload-loading-ani3 0.475s linear infinite;
  -ms-animation: cssload-loading-ani3 0.475s linear infinite;
  -webkit-animation: cssload-loading-ani3 0.475s linear infinite;
  -moz-animation: cssload-loading-ani3 0.475s linear infinite;
  transform: translate(-119px);
  -o-transform: translate(-119px);
  -ms-transform: translate(-119px);
  -webkit-transform: translate(-119px);
  -moz-transform: translate(-119px);

}

.cssload-loading i:last-child {
  animation: cssload-loading-ani1 0.475s linear infinite;
  -o-animation: cssload-loading-ani1 0.475s linear infinite;
  -ms-animation: cssload-loading-ani1 0.475s linear infinite;
  -webkit-animation: cssload-loading-ani1 0.475s linear infinite;
  -moz-animation: cssload-loading-ani1 0.475s linear infinite;
  transform: translate(-59px);
  -o-transform: translate(-59px);
  -ms-transform: translate(-59px);
  -webkit-transform: translate(-59px);
  -moz-transform: translate(-59px);

}

@keyframes cssload-loading-ani1 {
  100% {
    transform: translate(138px);
    opacity: 0;
  }
}

@-o-keyframes cssload-loading-ani1 {
  100% {
    -o-transform: translate(138px);
    opacity: 0;
  }
}

@-ms-keyframes cssload-loading-ani1 {
  100% {
    -ms-transform: translate(138px);
    opacity: 0;
  }
}

@-webkit-keyframes cssload-loading-ani1 {
  100% {
    -webkit-transform: translate(138px);
    opacity: 0;
  }
}

@-moz-keyframes cssload-loading-ani1 {
  100% {
    -moz-transform: translate(138px);
    opacity: 0;
  }
}

@keyframes cssload-loading-ani2 {
  100% {
    transform: translate(219px);
    opacity: 1;
  }
}

@-o-keyframes cssload-loading-ani2 {
  100% {
    -o-transform: translate(219px);
    opacity: 1;
  }
}

@-ms-keyframes cssload-loading-ani2 {
  100% {
    -ms-transform: translate(219px);
    opacity: 1;
  }
}

@-webkit-keyframes cssload-loading-ani2 {
  100% {
    -webkit-transform: translate(219px);
    opacity: 1;
  }
}

@-moz-keyframes cssload-loading-ani2 {
  100% {
    -moz-transform: translate(219px);
    opacity: 1;
  }
}

@keyframes cssload-loading-ani3 {
  100% {
    transform: translate(219px);
  }
}

@-o-keyframes cssload-loading-ani3 {
  100% {
    -o-transform: translate(219px);
  }
}

@-ms-keyframes cssload-loading-ani3 {
  100% {
    -ms-transform: translate(219px);
  }
}

@-webkit-keyframes cssload-loading-ani3 {
  100% {
    -webkit-transform: translate(219px);
  }
}

@-moz-keyframes cssload-loading-ani3 {
  100% {
    -moz-transform: translate(219px);
  }
}

/* ==========================================================================
  04. HEADER & NAVIGATION
  =========================================================================== */

#header {
  width: 100%;
  display: block;
  padding-top: 0px;
}

.header-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.headerwp {
  max-width: 1140px;
  margin: 0 auto;
  padding: 0;
}

.posrlt {
  position: relative;
}

/*------------------------------------------*/
/*   NAVIGATION MENU
/*------------------------------------------*/

.wsmainfull {
  width: 100%;
  height: 'auto';
  z-index: 1031;
  -webkit-transition: all 450ms ease-in-out;
  -moz-transition: all 450ms ease-in-out;
  -o-transition: all 450ms ease-in-out;
  -ms-transition: all 450ms ease-in-out;
  transition: all 450ms ease-in-out;
}

.tra-menu .wsmainfull {
  background-color: transparent !important;
  padding: 20px 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.white-menu .wsmainfull {
  background-color: #fff !important;
  padding: 0px 0;
  -webkit-box-shadow: 0 2px 3px rgba(96, 96, 96, 0.1);
  -moz-box-shadow: 0 2px 3px rgba(96, 96, 96, 0.1);
  box-shadow: 0 2px 3px rgba(96, 96, 96, 0.1);
}

.dark-menu .wsmainfull {
  background-color: #283034 !important;
  padding: 0px 0;
  -webkit-box-shadow: 0 2px 3px rgba(96, 96, 96, 0.1);
  -moz-box-shadow: 0 2px 3px rgba(96, 96, 96, 0.1);
  box-shadow: 0 2px 3px rgba(96, 96, 96, 0.1);
}

/*------------------------------------------*/
/*  HEADER LINK
/*------------------------------------------*/

.wsmenu>.wsmenu-list>li>a {
  display: block;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0;
  margin: 0 7px;
  padding: 10px 15px;
  line-height: 30px;
  text-decoration: none;
}

.navbar-dark .wsmenu>.wsmenu-list>li>a {
  color: #444;
}

.navbar-light .wsmenu>.wsmenu-list>li>a {
  color: #fff;
}

.wsmenu>.wsmenu-list>li>a.last-link {
  padding: 10px 0px;
  margin: 0 0 0 7px;
}

.wsmenu>.wsmenu-list>li>a .wsarrow:after {
  border-left: 4px solid rgba(0, 0, 0, 0);
  border-right: 4px solid rgba(0, 0, 0, 0);
  border-top: 4px solid;
  content: "";
  float: right;
  right: 0;
  height: 0;
  margin: 0 0 0 14px;
  position: absolute;
  text-align: right;
  top: 23px;
  width: 0;
}

li>.lang-select {
  margin-right: 15px;
}

.wsmenu>.wsmenu-list>li>a img,
.wsmenu>.wsmenu-list>li ul.sub-menu a img {
  width: 26px;
  height: 26px;
  margin-right: 3px;
}

/*------------------------------------------*/
/*  HEADER BUTTON
/*------------------------------------------*/

.wsmenu>.wsmenu-list>li a.btn {
  font-size: 16px;
  line-height: 30px;
  font-weight: 500;
  text-transform: none;
  letter-spacing: 0.25px;
  margin-top: 13px;
  margin-left: 15px;
  padding: 6px 30px;
}

/*------------------------------------------*/
/*  HEADER SUBMENU
/*------------------------------------------*/

.wsmenu>.wsmenu-list>li>ul.sub-menu>li>a {
  font-size: 15px;
  padding: 11px 9px;
}

.wsmenu>.wsmenu-list>li>ul.sub-menu>li>a:hover {
  padding: 11px 9px 11px 15px;
}

.wsmenu>.wsmenu-list>li>.wsmegamenu .title {
  font-size: 15px;
  line-height: 1.3;
  font-weight: 800;
  font-family: "Muli", sans-serif;
  letter-spacing: 0;
}

/*------------------------------------------*/
/*   MEGAMENU LATEST NEWS
/*------------------------------------------*/

.wsmegamenu .latest-news li {
  padding: 15px 0;
  border-bottom: 1px dashed #c0c0c0;
}

.wsmegamenu .latest-news li:first-child {
  padding: 0 0 15px;
}

.wsmegamenu .latest-news li:last-child {
  padding: 15px 0 0;
  border-bottom: 0;
}

.wsmegamenu .latest-news img {
  text-align: center;
  float: left;
  width: 85px;
  height: 85px;
}

.wsmegamenu .post-summary {
  overflow: hidden;
  padding-left: 20px;
}

/*------------------------------------------*/
/*  MEGAMENU LATEST NEWS TYPOGRAPHY
--------------------------------------------*/

.wsmegamenu .post-summary a {
  color: #888 !important;
  font-size: 16px;
  font-family: "Muli", sans-serif;
  font-weight: 400;
}

.wsmegamenu h5.h5-md a {
  color: #333 !important;
  margin-bottom: 0;
}

.wsmegamenu .latest-news .post-summary a:hover {
  color: #333 !important;
  text-decoration: underline;
}

.wsmegamenu .latest-news p {
  color: #999;
  font-size: 15px;
  font-weight: 300;
  margin-top: 6px;
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  NAVBAR SCROLL
/*------------------------------------------*/

.tra-menu .wsmainfull.scroll,
.white-menu .wsmainfull.scroll,
.dark-menu.dark-scroll .wsmainfull.scroll {
  background-color: #fff !important;
  padding: 0px 0;
  -webkit-box-shadow: 0 2px 3px rgba(96, 96, 96, 0.1);
  -moz-box-shadow: 0 2px 3px rgba(96, 96, 96, 0.1);
  box-shadow: 0 2px 3px rgba(96, 96, 96, 0.1);
}

/*------------------------------------------*/
/*  Navigation Menu
/*------------------------------------------*/

.tra-menu.navbar-light .scroll .wsmenu>.wsmenu-list>li a {
  color: #444;
}

.tra-menu.navbar-light.dark-scroll .scroll .wsmenu>.wsmenu-list>li a,
.tra-menu.navbar-dark.dark-scroll .scroll .wsmenu>.wsmenu-list>li a {
  color: #fff;
}

.tra-menu.navbar-light.dark-scroll .scroll .wsmenu>.wsmenu-list .sub-menu li a,
.tra-menu.navbar-dark.dark-scroll .scroll .wsmenu>.wsmenu-list .sub-menu li a,
.tra-menu.navbar-light.dark-scroll .scroll .wsmenu>.wsmenu-list li .wsmegamenu a,
.tra-menu.navbar-dark.dark-scroll .scroll .wsmenu>.wsmenu-list li .wsmegamenu a {
  color: #666;
}

/*------------------------------------------*/
/*  Logo Image
/*------------------------------------------*/

.logo-white,
.logo-black {
  display: block;
}

.navbar-light .logo-black,
.navbar-dark .logo-white,
.tra-menu.navbar-light .scroll .logo-white,
.tra-menu.navbar-light.dark-scroll .scroll .logo-black,
.tra-menu.navbar-dark.dark-scroll .scroll .logo-black {
  display: none;
}

.tra-menu.navbar-light .scroll .logo-black,
.tra-menu.navbar-light.dark-scroll .scroll .logo-white,
.tra-menu.navbar-dark.dark-scroll .scroll .logo-white {
  display: block;
}

/* ==========================================================================
  05. HERO
  =========================================================================== */

#hero-1 {
  background-color: #222120e6;
  padding-top: 180px;
  padding-bottom: 50px;
}

#hero-2 {
  background-color: #d6a504;
  padding-top: 180px;
  padding-bottom: 50px;
}

#hero-main {
  background-color: #001f3f;
  padding-top: 120px;
  padding-bottom: 50px;
}

#hero-write {
  background-color: #000000;
  padding-top: 120px;
  padding-bottom: 50px;
}



#hero-black {
  background-color: #151618;
  padding-top: 50px;
  padding-bottom: 50px;
}

#hero-art {
  background-color: #352222;
  padding-top: 100px;
  padding-bottom: 30px;
}

#hero-3 {
  background-color: #ffffff;
  padding-top: 100px;
  padding-bottom: 30px;
}

#hero-3-sub {
  background-color: #ffffff;
  padding-top: 50px;
  padding-bottom: 30px;
}

#hero-4 {
  background-color: #cee2e7;
  padding-top: 40px;
  padding-bottom: 40px;
}

/*------------------------------------------*/
/*  HERO SLIDER
/*------------------------------------------*/

.slider {
  position: relative;
  max-width: 100%;
  height: 600px;
  margin-top: 85px;
}

.slider .swiper-wrapper {
  margin: 0;
  height: 600px;
}

.slider .swiper-wrapper .li {
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: inherit;
  overflow: hidden;
}

.slider .swiper-wrapper .li img {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
}

.slider .swiper-wrapper .li .caption {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
}

.slider .swiper-wrapper .li.active {
  z-index: 2;
}

/*------------------------------------------*/
/*  Slider Animation
/*------------------------------------------*/

.center-align {
  text-align: center;
}

/*------------------------------------------*/
/*  Slider Indicators
/*------------------------------------------*/

.slider .indicators {
  position: absolute;
  text-align: center;
  left: 0;
  right: 0;
  bottom: 30px;
  margin: 0;
  z-index: 98;
}

.slider .indicators .swiper-pagination-bullet {
  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  height: 10px;
  width: 10px;
  border: 2px solid #fff;
  margin: 0 7px;
  opacity: 0.5;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  border-radius: 50%;
}

.slider .indicators .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #fff;
  opacity: 0.65;
}

/*------------------------------------------*/
/*  Hero Headers
/*------------------------------------------*/

#hero-1 h2 {
  font-size: 3.35rem;
  font-weight: 900;
  letter-spacing: 0;
  text-transform: uppercase;
  margin-bottom: 20px;
}

#hero-2 h3 {
  font-size: 3.15rem;
  font-weight: 800;
  margin-bottom: 20px;
}

#hero-write h3 {
  margin-bottom: 20px;
}

#hero-main h3 {
  margin-bottom: 20px;
}

#hero-art h3 {
  font-size: 3.15rem;
  font-weight: 800;
  margin-bottom: 20px;
}

#hero-2 h5 {
  font-weight: 600;
  letter-spacing: 0;
  margin-bottom: 25px;
}

/*------------------------------------------*/
/*  Hero Paragraphs
/*------------------------------------------*/

#hero-1 .caption-txt p {
  padding-right: 15%;
  margin-bottom: 30px;
}

#hero-2 .hero-txt p {
  padding-right: 5%;
  margin-bottom: 30px;
}

/*------------------------------------------*/
/*  HERO SEARCH FORM
/*------------------------------------------*/

#hero-1 .hero-form {
  margin: 40px 15% 0 0;
}

/*------------------------------------------*/
/*  HERO SEARCH FORM INPUT
/*------------------------------------------*/

.hero-form .form-control {
  height: 60px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-right: none;
  color: #333;
  font-size: 1.125rem;
  padding: 0 20px;
  -webkit-border-radius: 8px 0 0 8px;
  -moz-border-radius: 8px 0 0 8px;
  border-radius: 8px 0 0 8px;
  -webkit-transition: all 450ms ease-in-out;
  -moz-transition: all 450ms ease-in-out;
  -o-transition: all 450ms ease-in-out;
  -ms-transition: all 450ms ease-in-out;
  transition: all 450ms ease-in-out;
}

/*------------------------------------------*/
/*  Newsletter Form Input Focus
/*------------------------------------------*/

.hero-form .form-control:focus {
  border: 1px solid #ccc;
  border-right: none;
  outline: 0;
  box-shadow: none;
}

/*------------------------------------------*/
/*  Newsletter Form Button
/*------------------------------------------*/

.hero-form .btn {
  height: 60px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-left: none;
  font-size: 1.45rem;
  line-height: 1 !important;
  padding: 0 20px;
  -webkit-border-radius: 0 8px 8px 0;
  -moz-border-radius: 0 8px 8px 0;
  border-radius: 0 8px 8px 0;
}

.hero-form .btn i {
  color: #ec5252;
  font-size: 1.125rem;
  line-height: 1;
}


/*------------------------------------------*/
/*  HERO IMAGE
/*------------------------------------------*/

.hero-2-img {
  padding-left: 30px;
  padding-right: 20px;
}

.slack-eg {
  max-width: 550px;
  width: 90%;
  margin-top: 40px;
  margin-bottom: 40px;
}




/* ==========================================================================
  13. PRICING
  =========================================================================== */

/*------------------------------------------*/
/*  PRICING TABLE
/*------------------------------------------*/

#pricing-1 .currentplan-table {
  background-color: #fff;
  border: 2px solid green;
  text-align: center;
  padding: 20px 20px;
  margin-bottom: 40px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  overflow: hidden;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

#pricing-1 .pricing-table {
  background-color: #fff;
  border: 1px solid #ddd;
  text-align: center;
  padding: 20px 20px;
  margin-bottom: 40px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  overflow: hidden;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}


#pricing-1 .pricing-table:hover {
  background-color: #f5f6f8;
  border-color: transparent;
  box-shadow: 0px 6px 15px 0px rgba(0, 0, 0, 0.15);
}

#pricing-2 .pricing-table {
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 30px 70px 45px;
  margin: 0 20px 40px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}

/*------------------------------------------*/
/*  Pricing Table Price Plan
/*------------------------------------------*/

.pricing-plan h5 {
  font-size: 1.05rem;
  font-weight: 800;
  letter-spacing: 0;
  text-transform: uppercase;
  margin-bottom: 30px;
}

#pricing-2 .pricing-plan h5 {
  font-size: 1.45rem;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 0;
  text-transform: none;
  margin-top: 5px;
  margin-bottom: 5px;
}

.pricing-table span.price {
  font-size: 75px;
  line-height: 1;
  font-weight: 800;
  letter-spacing: -2px;
}

.pricing-table sup {
  font-size: 40px;
  font-weight: 500;
  top: -7px;
  right: 3px;
}

.pricing-table sup.coins {
  font-weight: 500;
  font-size: 40px;
  top: -22px;
  left: 1px;
}

.pricing-table p.p-sm {
  color: #999;
  font-size: 1.15rem;
  line-height: 1;
  margin-top: 5px;
  margin-bottom: 0;
}

#pricing-2 .pricing-plan p {
  color: #999;
  font-size: 1.05rem;
  font-weight: 400;
}

/*------------------------------------------*/
/*  Pricing Table Body
/*------------------------------------------*/

ul.features {
  color: #757575;
  font-size: 1.05rem;
  line-height: 1;
  font-weight: 400;
  margin-top: 25px;
  margin-bottom: 30px;
}

#pricing-2 ul.features {
  font-size: 1rem;
  margin-top: 25px;
  margin-bottom: 25px;
}

ul.features li {
  padding: 12px 5%;
}

#pricing-2 ul.features li {
  position: relative;
  padding: 7px 0;
}

#pricing-2 ul.features li span {
  position: absolute;
  color: #00a344;
  font-size: 1.25rem;
  line-height: 1;
  right: 0;
  bottom: 6px;
}

ul.features li.disabled-option {
  color: #ccc;
}

/*------------------------------------------*/
/*  Pricing Table Button
/*------------------------------------------*/

#pricing-1 .pricing-table .btn,
#pricing-2 .pricing-table .btn {
  display: block;
  width: 100%;
}

/*------------------------------------------*/
/*  PRICING TEXT
/*------------------------------------------*/

.pricing-txt .txt-list li {
  margin-bottom: 12px;
}

/*------------------------------------------*/
/*  PRICING NOTICE TEXT
/*------------------------------------------*/

.pricing-notice {
  margin-top: 30px;
  margin-bottom: 40px;
}

.pricing-notice p {
  padding: 0 8%;
  margin-bottom: 0;
}

.pricing-notice p span {
  font-weight: 700;
  margin-right: 5px;
}

.error {
  color: #e74c3c;
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 20px;
}

/*------------------------------------------*/
/*  Article Tags Cloud
/*------------------------------------------*/

span.badge {
  display: inline-block;
  font-weight: 600;
  border: none;
  background-color: #fff;
  border: 1px solid #999;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  margin-bottom: 8px;
  padding: 4px 14px;
  margin-right: 5px;
  -webkit-transition: all 450ms ease-in-out;
  -moz-transition: all 450ms ease-in-out;
  -o-transition: all 450ms ease-in-out;
  -ms-transition: all 450ms ease-in-out;
  transition: all 450ms ease-in-out;
}

span.badge a {
  color: #999 !important;
  font-size: 0.975rem;
  font-weight: 400;
  line-height: 1.42857;
}

span.badge:hover {
  background-color: #283034;
  border-color: #283034;
}

span.badge:hover a {
  color: #fff !important;
}

/*------------------------------------------*/
/*  Article Preview Hover Overlay
/*------------------------------------------*/

.hover-overlay {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.hover-overlay>img {
  transform: scale(1);
  -ms-transform: scale(1);
  -webkit-transform: scale(1);
  -o-transform: scale(1);
  -moz-transform: scale(1);
  overflow: hidden;
  -webkit-transition: transform 500ms;
  -moz-transition: transform 500ms;
  -o-transition: transform 500ms;
  transition: transform 500ms;
}


.error {
  color: #e74c3c;
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 20px;
}

/* ==========================================================================
  18. FAQs
  =========================================================================== */

#faqs-1 .tabs-nav {
  margin-right: 40px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  border: 1px solid #ddd;
  overflow: hidden !important;
}

#faqs-1 .tabs-1 {
  width: 100%;
  margin: 0;
}

#faqs-1 .tab-content {
  display: none;
}

#faqs-1 .tab-content.current,
#faqs-1 .tab-content.displayed {
  display: inherit;
}

/*------------------------------------------*/
/*  FAQS CATEGORY TITLE
/*------------------------------------------*/

#faqs-1 .category-title h4 {
  line-height: 1;
  margin-bottom: 15px;
}

#faqs-1 .category-title p {
  color: #757575;
  margin-bottom: 30px;
}

/*------------------------------------------*/
/*  FAQS FILTER BUTTONS
/*------------------------------------------*/

#faqs-1 .tabs-1 li {
  position: relative;
  width: 100%;
  background-color: #fff;
  color: #888;
  margin: 0;
  padding: 26px 30px;
  cursor: pointer;
  border-top: 1px dashed #ddd;
  -webkit-transition: all 450ms ease-in-out;
  -moz-transition: all 450ms ease-in-out;
  -o-transition: all 450ms ease-in-out;
  -ms-transition: all 450ms ease-in-out;
  transition: all 450ms ease-in-out;
}

#faqs-1 .tabs-1 li:first-child {
  border-top: none;
}

#faqs-1 .tabs-1 li.current,
#faqs-1 .tabs-1 li.current:hover {
  background-color: #f0f1f3;
  border-top: 1px solid #f0f1f3;
}

#faqs-1 .tab-link img {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  text-align: center;
  float: left;
}

#faqs-1 .tab-link.current img {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}

#faqs-1 .tab-link-txt {
  overflow: hidden;
  padding-left: 15px;
}

#faqs-1 .tabs-1 li h5 {
  font-size: 1.125rem;
  line-height: 1;
  margin-bottom: 8px;
  -webkit-transition: all 450ms ease-in-out;
  -moz-transition: all 450ms ease-in-out;
  -o-transition: all 450ms ease-in-out;
  -ms-transition: all 450ms ease-in-out;
  transition: all 450ms ease-in-out;
}

#faqs-1 .tabs-1 li p {
  color: #888;
  font-size: 0.95rem;
  line-height: 1.3;
  margin-bottom: 0;
  -webkit-transition: all 450ms ease-in-out;
  -moz-transition: all 450ms ease-in-out;
  -o-transition: all 450ms ease-in-out;
  -ms-transition: all 450ms ease-in-out;
  transition: all 450ms ease-in-out;
}

/*------------------------------------------*/
/*  Question
/*------------------------------------------*/

.question {
  margin-top: 50px;
  margin-bottom: 55px;
}

.question h5 {
  margin-bottom: 15px;
}

.question p,
.question li {
  color: #757575;
}



/* ==========================================================================
  20. CONTACTS
  =========================================================================== */

#contacts-1 .container {
  padding-left: 0;
  padding-right: 0;
}

.contacts-1-holder {
  padding-top: 60px;
  padding-bottom: 60px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  overflow: hidden;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

#contacts-21 {
  padding-top: 80px;
  padding-bottom: 80px;
}

.contacts-2-holder {
  background-color: #fff;
  padding-left: 15px;
  padding-right: 15px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  overflow: hidden;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

#contacts-2 .col-lg-4 {
  padding-left: 0;
  padding-right: 0;
}

.contacts-txt p {
  font-weight: 400;
  margin-top: 15px;
  margin-bottom: 25px;
}

/*------------------------------------------*/
/*  CONTACT BOX
/*------------------------------------------*/

.contact-box {
  text-align: center;
  padding: 60px 20px;
}

.contact-box h5 {
  margin-top: 30px;
  margin-bottom: 10px;
}

.contact-box p {
  color: #757575;
  padding: 0 5%;
  margin-bottom: 20px;
}

/* ==========================================================================
  21. GOOGLE MAP
  =========================================================================== */

.google-map iframe {
  width: 100%;
  height: 450px;
  border: none;
}

/* ==========================================================================
  22. FOOTER
  =========================================================================== */

.footer {
  padding-top: 100px;
  padding-bottom: 30px;
}

#footer-1 .footer-links {
  padding-left: 25%;
}

#footer-2 .footer-links {
  padding-left: 10%;
}

#footer-3 .footer-links {
  padding-left: 5%;
}

/*------------------------------------------*/
/*  FOOTER TYPOGRAPHY
/*------------------------------------------*/

.footer h5 {
  letter-spacing: 0;
  margin-bottom: 30px;
}

.footer-info p {
  margin-top: 25px;
}

.footer-box p {
  font-size: 1.05rem;
  margin-bottom: 4px;
}

.footer-contacts p {
  font-size: 1.05rem;
  margin-bottom: 1px;
}

.footer-contacts .btn {
  width: 100%;
  margin-top: 5px;
  margin-bottom: 20px;
}

.bg-lightdark .footer-info p,
.bg-lightdark .footer-links li a,
.bg-lightdark .footer-copyright p,
.bg-lightdark .bottom-footer-list a {
  color: #b3b8c3;
}

.bg-lightdark .footer-links li a:hover,
.bg-lightdark .bottom-footer-list a:hover {
  color: #fff;
}

/*------------------------------------------*/
/*  FOOTER LINKS
/*------------------------------------------*/

.footer-links li {
  width: auto !important;
  display: block !important;
  vertical-align: top;
  clear: none !important;
  font-size: 1.05rem;
  margin: 0 0 9px 0;
  padding: 0;
}

/*------------------------------------------*/
/*  FOOTER SOCIAL LINKS
/*------------------------------------------*/

.foo-socials {
  display: inline-block;
  padding-left: 0;
  margin: 10px auto 0;
}

.bottom-footer .foo-socials {
  margin: 0;
}

.foo-socials li {
  width: auto !important;
  display: inline-block !important;
  vertical-align: top;
  clear: none !important;
  margin: 0;
  padding: 0;
}

.foo-socials a {
  color: #888;
  font-size: 1rem;
  line-height: 30px !important;
  margin-right: 22px;
  display: block;
}

.foo-socials a.ico-facebook:hover {
  color: #3b5998;
}

.foo-socials a.ico-twitter:hover {
  color: #00a9ed;
}

.foo-socials a.ico-instagram:hover {
  color: #e44772;
}

.foo-socials a.ico-dribbble:hover {
  color: #d92d84;
}

.foo-socials a.ico-behance:hover {
  color: #2473f6;
}

.foo-socials a.ico-pinterest:hover {
  color: #ac281a;
}

.foo-socials a.ico-linkedin:hover {
  color: #015886;
}

.foo-socials a.ico-google-plus:hover {
  color: #cd1111;
}

.foo-socials a.ico-youtube:hover {
  color: #cd1b20;
}

.foo-socials a.ico-tumblr:hover {
  color: #3a5976;
}

.foo-socials a.ico-vk:hover {
  color: #3b5998;
}

/*------------------------------------------*/
/*  FOOTER NEWSLETTER FORM
/*------------------------------------------*/

.footer-form .form-control {
  text-align: center;
  height: 48px;
  background-color: #fff;
  border: 1px solid #ccc;
  color: #757575;
  font-size: 15px;
  padding: 0 15px;
  margin-bottom: 10px;
  -webkit-border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

.bg-lightdark .footer-form .form-control {
  color: #fff;
}

.footer-form .input-group>.custom-select:not(:last-child),
.footer-form .input-group>.form-control:not(:last-child) {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.bg-lightdark .footer-form .form-control {
  background-color: #262d31;
  border-color: #5b6c75;
}

/*------------------------------------------*/
/*  Contact Form Placeholder
/*------------------------------------------*/

.bg-lightdark .footer-form .form-control::-moz-placeholder {
  color: #b3b8c3;
}

.bg-lightdark .footer-form .form-control:-ms-input-placeholder {
  color: #b3b8c3;
}

.bg-lightdark .footer-form .form-control::-webkit-input-placeholder {
  color: #b3b8c3;
}

/*

/*------------------------------------------*/
/*  Newsletter Form Input Focus
/*------------------------------------------*/

.footer-form .form-control:focus {
  background-color: #fff !important;
  border: 1px solid #ccc;
  outline: 0;
  box-shadow: none;
}

.bg-lightdark .footer-form .form-control:focus {
  background-color: #384349 !important;
  border: 1px solid #323c41;
}

.footer-form input:-internal-autofill-selected {
  background-color: #fff !important;
}

/*------------------------------------------*/
/*  Newsletter Form Button
/*------------------------------------------*/

.footer-form .input-group-btn {
  display: block;
  width: 100% !important;
}

.footer-form .btn {
  display: block;
  width: 100% !important;
  height: 48px;
  font-size: 15px;
  padding: 0 15px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

/*------------------------------------------*/
/*  Newsletter Form Notification
/*------------------------------------------*/

.footer-form .form-notification {
  font-size: 0.925rem;
  font-weight: 400;
  line-height: 1;
  margin-top: 15px;
  margin-left: 5px;
}

/*------------------------------------------*/
/*  BOTTOM FOOTER
/*------------------------------------------*/

.bottom-footer {
  margin-top: 10px;
  padding-top: 15px;
}

.bg-lightdark .bottom-footer {
  border-top: 1px solid #414e55;
}

.bottom-footer p {
  margin-bottom: 0;
}

.bottom-footer p.p-sm {
  display: inline-block;
  font-size: 0.925rem;
  line-height: 1;
  margin-left: 17px;
  margin-bottom: 0;
}

.bg-dark .bottom-footer p.p-sm {
  color: #ddd;
}

/*------------------------------------------*/
/*  Bottom Footer List
/*------------------------------------------*/

.bottom-footer-list li {
  font-size: 1rem;
  width: auto !important;
  display: inline-block !important;
  vertical-align: top;
  clear: none !important;
  margin: 0 9px 5px 0;
  padding: 0;
}

.bottom-footer-list li a {
  font-weight: 600;
}

.bottom-footer-list p:after {
  content: "|";
  padding-left: 11px;
  position: relative;
  top: -1px;
}

.bottom-footer-list p.last-li:after {
  content: " ";
  padding-left: 0;
}

/* ==========================================================================
  23. INNER PAGE WRAPPER
  =========================================================================== */

.inner-page-wrapper {
  margin-top: 70px;
}

/* ==========================================================================
  24. BREADCRUMB
  =========================================================================== */

.breadcrumb {
  padding: 16px 5px 14px;
  background-color: transparent;
  margin-bottom: 0;
  border-radius: 0;
  border-bottom: 1px solid #efefef;
}

/*------------------------------------------*/
/*  BREADCRUMB TYPOGRAPHY
/*------------------------------------------*/

.breadcrumb-item a,
.breadcrumb-item.active {
  color: #343536;
  font-size: 0.925rem;
  font-weight: 600;
  padding-bottom: 10px;
}

.breadcrumb-item.active {
  color: #999;
  font-weight: 500;
  padding-top: 2px;
}

.breadcrumb-item+.breadcrumb-item::before {
  font-size: 1.05rem;
  display: inline-block;
  padding-right: 9px;
  margin-top: -3px;
  content: "\00bb";
}

.breadcrumb-item+.breadcrumb-item {
  padding-left: 11px;
}

/* ==========================================================================
  25. INNER PAGE HERO
  =========================================================================== */

.page-hero-section {
  padding-top: 40px;
  padding-bottom: 40px;
}

.page-hero-section h4,
.page-hero-section h3 {
  line-height: 1;
  margin-bottom: 15px;
}

.page-hero-section p {
  font-size: 1.05rem;
  line-height: 18px;
  font-weight: 300;
  margin-bottom: 13px;
}

.page-hero-section p span {
  font-weight: 700;
}

/*------------------------------------------*/
/*  PAGE HERO SHARE ICONS
/*-----------------------------------------*/

.page-hero-section .share-social-icons {
  display: inline-block;
  padding-left: 0;
}

.page-hero-section .share-social-icons li {
  width: auto !important;
  display: inline-block !important;
  vertical-align: top;
  clear: none !important;
  padding: 0;
}

.page-hero-section .share-social-icons li p {
  font-size: 1.05rem;
  line-height: 18px;
  font-weight: 300;
  margin-right: 12px;
  margin-bottom: 3px;
}

.page-hero-section .share-social-icons li p span {
  font-weight: 700;
}

.page-hero-section a.share-ico {
  display: block;
  font-size: 16px;
  line-height: 18px;
  font-weight: 700;
  margin-right: 12px;
  opacity: 0.85;
}

.page-hero-section a.share-ico i {
  position: relative;
  font-size: 16px;
  line-height: 1;
  margin-right: 3px;
  top: 1px;
}

.page-hero-section a.share-ico:hover {
  opacity: 1;
}

/* ==========================================================================
  26. SIDEBAR
  =========================================================================== */

/*------------------------------------------*/
/*   SIDEBAR SEARCH FORM
/*------------------------------------------*/

#search-field .form-control {
  background-color: #f0f0f0;
  height: 54px;
  font-size: 17px;
  padding-left: 20px;
  border: none;
  -webkit-border-radius: 6px 0 0 6px;
  -moz-border-radius: 6px 0 0 6px;
  border-radius: 6px 0 0 6px;
}

#search-field .btn {
  background-color: #f0f0f0;
  color: #777;
  border: none;
  height: 54px;
  padding: 0 18px;
  margin-top: 0;
  -webkit-box-shadow: 0 0 0;
  -moz-box-shadow: 0 0 0;
  box-shadow: 0 0 0;
  -webkit-border-radius: 0px 6px 6px 0;
  -moz-border-radius: 0px 6px 6px 0;
  border-radius: 0px 6px 6px 0;
}

#search-field .form-control:focus {
  border-color: #999;
  outline: 0px none;
  box-shadow: none;
}

/* ==========================================================================
  29. SCROLL TO TOP
  =========================================================================== */

#scrollUp {
  display: none;
  width: 40px;
  height: 45px;
  position: fixed;
  bottom: 20px;
  right: 40px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  background-image: url(../../public/images/back-to-top.png);
  background-repeat: no-repeat;
  background-position: 50% 48%;
  background-color: rgba(35, 35, 35, 0.65);
  -webkit-transition: all 250ms linear;
  -moz-transition: all 250ms linear;
  transition: all 250ms linear;
}

#scrollUp:hover {
  background-color: #151515;
}

nav a#pull {
  display: none;
}