/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */

@media (min-width: 1921px) {}




/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */

@media (min-width: 1440px) and (max-width: 1920.95px) {}




/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */

@media (min-width: 1440px) and (max-width: 1920.95px) {}




/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */

@media (min-width: 1200px) {}




/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */

@media (min-width: 992px) and (max-width: 1199.95px) {

  /*------------------------------------------*/
  /*   BASE
  /*------------------------------------------*/

  .pc-20,
  .pc-25 {
    padding-right: 0;
    padding-left: 0;
  }

  /* Header H5 */
  h5.h5-xs {
    font-size: 1.09375rem;
  }

  h5.h5-sm {
    font-size: 1.125rem;
  }

  h5.h5-md {
    font-size: 1.15625rem;
  }

  h5.h5-lg {
    font-size: 1.1875rem;
  }

  h5.h5-xl {
    font-size: 1.225rem;
  }

  /* Header H4 */
  h4.h4-xs {
    font-size: 1.25rem;
  }

  h4.h4-sm {
    font-size: 1.375rem;
  }

  h4.h4-md {
    font-size: 1.5rem;
  }

  h4.h4-lg {
    font-size: 1.625rem;
  }

  h4.h4-xl {
    font-size: 1.875rem;
  }

  /* Header H3 */
  h3.h3-xs {
    font-size: 1.95rem;
  }

  h3.h3-sm {
    font-size: 2.125rem;
  }

  h3.h3-md {
    font-size: 2.25rem;
  }

  h3.h3-lg {
    font-size: 2.4rem;
  }

  h3.h3-xl {
    font-size: 2.5rem;
  }

  h1.h1-lg {
    font-size: 2rem;
  }

  /* Header H2 */
  h2.h2-xs {
    font-size: 2.75rem;
  }

  h2.h2-sm {
    font-size: 2.875rem;
  }

  h2.h2-md {
    font-size: 2.5rem;
  }

  h2.h2-lg {
    font-size: 3.125rem;
  }

  h2.h2-xl {
    font-size: 3.25rem;
  }

  h2.h2-huge {
    font-size: 4.25rem;
  }

  /* Paragraphs */
  p.p-sm {
    font-size: 0.7rem;
  }

  p {
    font-size: 0.85rem;
  }

  p.p-md {
    font-size: 1rem;
  }

  /* List Settings */
  ul.txt-list li,
  ul.ico-list li {
    font-size: 0.85rem;
    margin-bottom: 5px;
  }

  .txt-list.mb-15 {
    margin-top: 10px;
  }

  /* PNG Icons Settings */
  .img-95 {
    width: 90px;
    height: 90px;
  }

  .img-90 {
    width: 85px;
    height: 85px;
  }

  .img-85 {
    width: 80px;
    height: 80px;
  }

  .img-80 {
    width: 75px;
    height: 75px;
  }

  .img-75 {
    width: 70px;
    height: 70px;
  }

  .img-70 {
    width: 65px;
    height: 65px;
  }

  .img-65 {
    width: 60px;
    height: 60px;
  }

  /* Section Title */
  .section-title {
    margin-bottom: 50px;
    padding-right: 40%;
  }

  .section-title.title-centered {
    padding: 0 20%;
  }

  .section-title h4 {
    font-size: 1.875rem;
  }

  .section-title.title-centered p {
    padding: 0;
  }

  /* Text Block Typography */
  .txt-block h3.h3-sm {
    margin-bottom: 20px;
  }

  .txt-block .btn {
    margin-top: 10px;
  }

  /*------------------------------------------*/
  /*   NAVIGATION MENU    
  /*------------------------------------------*/

  .wsmenu>.wsmenu-list>li>a {
    font-size: 15px;
    margin: 0 2px;
    padding: 10px 15px;
  }

  .wsmenu>.wsmenu-list>li>a .wsarrow:after {
    right: 3px;
    margin: 0 0 0 14px;
    top: 23px;
  }

  .wsmenu>.wsmenu-list>li>ul.sub-menu>li>a {
    font-size: 15px;
  }

  .wsmenu>.wsmenu-list>li a.btn {
    font-size: 15px;
    padding: 4px 25px;
    margin-top: 14px;
  }


  /* Hero Slider */
  .slider,
  .slider .slides {
    height: 580px;
  }

  /* Hero Headers */
  #hero-1 h2 {
    font-size: 2.85rem;
    margin-bottom: 20px;
  }

  #hero-2 h3 {
    font-size: 2.75rem;
    margin-bottom: 20px;
  }

  #hero-2 h5 {
    font-size: 1.35rem;
  }

  #hero-5 h2 {
    font-size: 3.5rem;
    padding: 0 10%;
    margin-bottom: 20px;
  }

  /* Hero Paragraphs */
  #hero-1 .caption-txt p {
    padding-right: 10%;
    margin-bottom: 30px;
  }

  #hero-2 .hero-txt p {
    padding-right: 0;
    margin-bottom: 30px;
  }

  /* Hero Search Form */
  #hero-1 .hero-form {
    margin: 40px 10% 0 0;
  }





  #pricing-2 .pricing-table {
    padding: 30px 45px 45px;
    margin: 0 0 40px;
  }

  ul.features {
    font-size: 1.1rem;
  }

  ul.features li {
    padding: 10px 0;
  }


  span.badge {
    margin-bottom: 8px;
    padding: 4px 12px;
    margin-right: 4px;
  }

  span.badge a {
    font-size: 0.95rem;
  }

  /* FAQs */
  #faqs-1 .tabs-nav {
    margin-right: 0;
  }

  #faqs-1 .tabs-1 li {
    padding: 25px 15px;
  }


  /* Contacts */
  .contacts-1-holder {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .contact-box {
    padding: 50px 20px;
  }

  .contact-box h5 {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .contact-box img.img-75 {
    width: 70px;
    height: 70px;
  }

  /* Footer */
  .footer {
    padding-top: 80px;
    padding-bottom: 30px;
  }

  #footer-1 .footer-links,
  #footer-21 .footer-links {
    padding-left: 0;
  }

  .footer h5 {
    margin-bottom: 22px;
  }

  .footer-info p {
    padding-right: 10%;
  }

  .footer-box p,
  .footer-contacts p,
  .footer-links li {
    font-size: 1rem;
  }



}




/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */

@media (min-width: 768px) and (max-width: 991.95px) {

  /*------------------------------------------*/
  /*   BASE
  /*------------------------------------------*/

  .wide-100 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .wide-90 {
    padding-top: 80px;
    padding-bottom: 70px;
  }

  .wide-80 {
    padding-top: 80px;
    padding-bottom: 60px;
  }

  .wide-70 {
    padding-top: 80px;
    padding-bottom: 50px;
  }

  .wide-60 {
    padding-top: 80px;
    padding-bottom: 40px;
  }

  .wide-50 {
    padding-top: 80px;
    padding-bottom: 30px;
  }

  .wide-40 {
    padding-top: 80px;
    padding-bottom: 20px;
  }

  .wide-30 {
    padding-top: 80px;
    padding-bottom: 10px;
  }

  .wide-20 {
    padding-top: 80px;
    padding-bottom: 0;
  }

  .pt-100,
  .division.pt-100 {
    padding-top: 80px;
  }

  .bg-scroll,
  .bg-01,
  .bg-02,
  .bg-03,
  .bg-04,
  .bg-map {
    background-attachment: scroll !important;
  }

  /* Header H5 */
  h5.h5-xs {
    font-size: 1.1rem;
  }

  h5.h5-sm {
    font-size: 1.2rem;
  }

  h5.h5-md {
    font-size: 1.3rem;
  }

  h5.h5-lg {
    font-size: 1.35rem;
  }

  h5.h5-xl {
    font-size: 1.4rem;
  }

  /* Header H4 */
  h4.h4-xs {
    font-size: 1.45rem;
  }

  h4.h4-sm {
    font-size: 1.5rem;
  }

  h4.h4-md {
    font-size: 1.6rem;
  }

  h4.h4-lg {
    font-size: 1.65rem;
  }

  h4.h4-xl {
    font-size: 1.7rem;
  }

  /* Header H3 */
  h3.h3-xs {
    font-size: 1.8rem;
  }

  h3.h3-sm {
    font-size: 1.9rem;
  }

  h3.h3-md {
    font-size: 2rem;
  }

  h3.h3-lg {
    font-size: 2.1rem;
  }

  h3.h3-xl {
    font-size: 2.15rem;
  }

  /* Header H2 */
  h2.h2-xs {
    font-size: 2.15rem;
  }

  h2.h2-sm {
    font-size: 2.45rem;
  }

  h2.h2-md {
    font-size: 2.65rem;
  }

  h2.h2-lg {
    font-size: 2.85rem;
  }

  h2.h2-xl {
    font-size: 3rem;
  }

  h2.h2-huge {
    font-size: 3.5rem;
  }

  /* Paragraphs */
  p.p-sm {
    font-size: 0.85rem;
  }

  p {
    font-size: 0.85rem;
  }

  p.p-md {
    font-size: 0.85rem;
  }

  p.p-lg {
    font-size: 0.85rem;
  }

  p.p-xl {
    font-size: 0.85rem;
  }

  /* List Settings */
  ul.txt-list li,
  ul.ico-list li {
    font-size: 0.85rem;
    margin-bottom: 5px;
  }

  .ico-list.mb-10 {
    margin-bottom: 15px;
  }

  ul.ico-list li i {
    font-size: 0.8rem;
    margin-top: 6px;
    margin-right: 8px;
  }

  .txt-list.mb-15 {
    margin-top: 10px;
  }

  /* Button */
  .btn {
    font-size: 15px;
    padding: 12px 24px;
  }

  .btn.btn-md {
    font-size: 16px;
    padding: 13px 26px;
  }

  /* Section Title */
  .section-title {
    margin-bottom: 50px;
    padding-right: 22%;
  }

  .section-title.title-centered {
    padding: 0 10%;
  }

  .section-title.title-centered p {
    padding: 0;
  }

  .section-title h4 {
    font-size: 1.7rem;
  }

  .section-title p {
    font-size: 1rem;
    padding-right: 10%;
  }

  .title-btn {
    right: -5px;
    bottom: 5px;
  }

  /* Text Block Typography */
  .txt-block h3.h3-sm {
    margin-bottom: 20px;
  }

  .txt-block .btn {
    margin-top: 10px;
  }

  /*------------------------------------------*/
  /*   NAVIGATION MENU    
  /*------------------------------------------*/

  .headtoppart {
    display: none;
  }

  .wsmobileheader {
    height: 50px;
    background-color: #fff;
    box-shadow: 0 0 2px rgba(50, 50, 50, 0.4);
  }

  .dark-menu .wsmobileheader {
    height: 50px;
    background-color: #283034 !important;
    box-shadow: 0 0 2px rgba(50, 50, 50, 0.4);
  }

  .wsanimated-arrow {
    padding: 14px 28px 14px 0px;
    margin: 24px 0 0 20px;
  }

  .wsanimated-arrow span:before {
    top: -8px;
    width: 26px;
  }

  .wsanimated-arrow span:after {
    bottom: -8px;
    width: 20px;
  }

  .wsactive .wsanimated-arrow span:before,
  .wsactive .wsanimated-arrow.active span:after {
    width: 28px;
    top: 0px;
  }

  .wsactive .wsanimated-arrow span:after {
    width: 28px;
    top: 0;
  }

  .wsactive .wsanimated-arrow span {
    background: transparent;
  }

  .dark-menu .wsanimated-arrow span,
  .dark-menu .wsanimated-arrow span:after,
  .dark-menu .wsanimated-arrow span:before {
    background: #fff;
  }

  .dark-menu .callusbtn {
    color: #fff;
  }

  .wsactive .dark-menu .wsanimated-arrow span {
    background: transparent;
  }

  .wsmenu>.wsmenu-list>li>a {
    font-size: 16px;
    padding: 5px 32px 5px 18px;
    margin: 0;
  }

  .tra-menu .wsmenu>.wsmenu-list>li>a,
  .dark-menu .wsmenu>.wsmenu-list>li>a {
    color: #666;
  }

  .wsmenu>.wsmenu-list>li>.wsmenu-click>i {
    margin: 22px 18px 0px 0px;
  }

  .wsmenu>.wsmenu-list>li>a>i {
    color: #fff;
  }

  .wsmenu>.wsmenu-list>li>ul.sub-menu {
    padding-left: 10px;
    padding-right: 10px;
  }

  .wsmenu>.wsmenu-list>li>ul.sub-menu>li>a,
  .wsmenu>.wsmenu-list>li>.wsmegamenu .link-list li a {
    color: #888;
    font-size: 16px;
    padding: 14px 9px;
  }

  .wsmenu>.wsmenu-list>li>.wsmegamenu.halfmenu {
    padding: 5px 0px 0 0px;
  }

  .wsmenu>.wsmenu-list>li>.wsmegamenu .link-list.left-link-list li:last-child {
    border-bottom: 1px dashed #c0c0c0;
  }

  .callusbtn {
    font-size: 22px;
    padding: 24px 20px;
  }

  .wsmenu>.wsmenu-list>li>a.btn {
    height: 60px;
    font-size: 18px;
    line-height: 56px;
    border: none;
    padding: 0 17px;
    margin: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
  }

  .wsmenu>.wsmenu-list>li a.btn.btn-tra-white {
    background-color: #333;
    color: #fff;
  }

  .wsmobileheader .smllogo {
    margin-top: 10px;
  }

  .wsmobileheader .smllogo-black,
  .dark-menu .wsmobileheader .smllogo-white {
    display: block;
  }

  .wsmobileheader .smllogo-white,
  .dark-menu .wsmobileheader .smllogo-black {
    display: none;
  }

  /*------------------------------------------*/
  /*  HERO
  /*------------------------------------------*/

  .hero-section {
    margin-top: 80px;
  }

  #hero-2 {
    padding-top: 90px;
    padding-bottom: 50px;
  }

  /* Hero Slider */
  .slider,
  .slider .slides {
    margin-top: 0;
    height: 530px;
  }


  /* Hero Headers */
  #hero-1 h2 {
    font-size: 2.65rem;
    margin-bottom: 20px;
  }

  #hero-2 h3 {
    font-size: 2.65rem;
    margin-bottom: 20px;
  }


  #hero-2 h5 {
    font-size: 1.35rem;
  }

  /* Hero Paragraphs */
  #hero-1 .caption-txt p {
    font-size: 1.066rem;
    padding-right: 0;
    margin-bottom: 30px;
  }

  #hero-2 .hero-txt p {
    padding-right: 0;
    margin-bottom: 30px;
  }


  /* Hero Search Form */
  #hero-1 .hero-form {
    margin: 40px 10% 0 0;
  }

  .old-price {
    font-size: 1.2rem;
  }


  /* Pricing */

  #pricing-2 .pricing-table {
    padding: 30px 70px 45px;
    margin: 0 5% 40px;
  }

  .pricing-plan h5 {
    font-size: 0.95rem;
  }

  .pricing-plan h4 {
    font-size: 1.1rem;
    margin-bottom: 25px;
  }

  .pricing-table span.price {
    font-size: 62px;
  }

  .pricing-table sup {
    font-size: 30px;
    top: -12px;
    right: 3px;
  }

  .pricing-table sup.coins {
    font-size: 30px;
    top: -23px;
    left: 1px;
  }

  ul.features {
    font-size: 0.935rem;
    line-height: 1.3;
  }

  ul.features li {
    padding: 8px 0;
  }

  #pricing-2 ul.features li {
    padding: 0 0 14px;
  }

  .pricing-plan p.p-sm {
    font-size: 1.2rem;
  }

  .pricing-table .btn {
    font-size: 15px;
    padding: 13px 12px;
    margin-top: 20px;
  }

  .pricing-txt {
    margin: 0 10% 40px;
  }

  .pricing-txt1 h3 {
    padding-right: 5%;
  }

  /* FAQs */
  #faqs-1 .tabs-nav {
    margin-right: 0;
    margin-bottom: 50px;
  }

  #faqs-1 .tabs-1 li h5 {
    font-size: 1.35rem;
  }

  #faqs-1 .tabs-1 li p {
    font-size: 1rem;
  }


  /* Contacts */
  #contacts-2 {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .contacts-1-holder {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .contact-box {
    padding: 50px 40px;
  }

  .contact-box.b-right {
    border-right: none;
    border-bottom: 1px solid #ddd;
  }

  .contact-box h5 {
    font-size: 1.3rem;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .contact-box p {
    font-size: 1.1rem;
  }

  .contact-box img.img-75 {
    width: 70px;
    height: 70px;
  }

  /* Footer */
  .footer {
    padding-top: 80px;
    padding-bottom: 30px;
  }

  #footer-1 .footer-links {
    padding-left: 0;
  }

  .footer h5 {
    font-size: 1.2rem;
    margin-bottom: 22px;
  }

  .footer-box p,
  .footer-contacts p,
  .footer-links li {
    font-size: 1rem;
  }

  .bottom-footer .col-lg-4.text-right {
    text-align: left !important;
    margin-top: 8px;
  }

  .footer-form .btn {
    font-size: 17px;
  }

  /* Inner Page Wrapper */
  .inner-page-wrapper {
    margin-top: 60px;
  }

  /* Breadcrumb */
  #breadcrumb .col-md-12 {
    padding-left: 0;
    padding-right: 0;
  }

  .breadcrumb {
    padding: 15px 5px;
  }

  .breadcrumb-item a,
  .breadcrumb-item.active {
    font-size: 0.975rem;
  }

  .breadcrumb-item+.breadcrumb-item::before {
    font-size: 1.1rem;
    margin-top: -1px;
  }

  /* Page Hero */
  .page-hero-section {
    padding-top: 40px;
    padding-bottom: 36px;
  }

  /* Sidebar */
  #sidebar {
    padding-bottom: 20px;
  }

  ul.blog-category-list>li {
    padding: 18px 0;
  }

  ul.blog-category-list>li:first-child {
    padding: 0 0 18px;
  }

  ul.blog-category-list>li:last-child {
    padding: 18px 0 0;
  }

  ul.blog-category-list>li a {
    font-size: 1.05rem;
  }

  /* Page Pagination */
  .page-pagination {
    padding-bottom: 80px;
  }


}




/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */

@media (max-width: 767px) {

  /*------------------------------------------*/
  /*   BASE
  /*------------------------------------------*/

  .wide-100 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .wide-90 {
    padding-top: 80px;
    padding-bottom: 70px;
  }

  .wide-80 {
    padding-top: 80px;
    padding-bottom: 60px;
  }

  .wide-70 {
    padding-top: 80px;
    padding-bottom: 50px;
  }

  .wide-60 {
    padding-top: 80px;
    padding-bottom: 40px;
  }

  .wide-50 {
    padding-top: 80px;
    padding-bottom: 30px;
  }

  .wide-40 {
    padding-top: 80px;
    padding-bottom: 20px;
  }

  .wide-30 {
    padding-top: 80px;
    padding-bottom: 10px;
  }

  .wide-20 {
    padding-top: 80px;
    padding-bottom: 0;
  }


  /* Section Title */
  .section-title.title-centered p {
    padding: 0;
  }

  /*------------------------------------------*/
  /*   NAVIGATION MENU    
  /*------------------------------------------*/

  .headtoppart {
    display: none;
  }

  .wsmobileheader {
    height: 50px;
    background-color: #fff;
    box-shadow: 0 0 2px rgba(50, 50, 50, 0.4);
  }

  .dark-menu .wsmobileheader {
    height: 50px;
    background-color: #283034 !important;
    box-shadow: 0 0 2px rgba(50, 50, 50, 0.4);
  }

  .wsanimated-arrow {
    padding: 14px 28px 14px 0px;
    margin: 24px 0 0 20px;
  }

  .wsanimated-arrow span:before {
    top: -8px;
    width: 26px;
  }

  .wsanimated-arrow span:after {
    bottom: -8px;
    width: 20px;
  }

  .wsactive .wsanimated-arrow span:before,
  .wsactive .wsanimated-arrow.active span:after {
    width: 28px;
    top: 0px;
  }

  .wsactive .wsanimated-arrow span:after {
    width: 28px;
    top: 0;
  }

  .wsactive .wsanimated-arrow span {
    background: transparent;
  }

  .dark-menu .wsanimated-arrow span,
  .dark-menu .wsanimated-arrow span:after,
  .dark-menu .wsanimated-arrow span:before {
    background: #fff;
  }

  .dark-menu .callusbtn {
    color: #fff;
  }

  .wsactive .dark-menu .wsanimated-arrow span {
    background: transparent;
  }

  .wsmenu>.wsmenu-list>li>a {
    font-size: 15px;
    padding: 5px 32px 5px 18px;
    margin: 0;
  }

  .tra-menu .wsmenu>.wsmenu-list>li>a,
  .dark-menu .wsmenu>.wsmenu-list>li>a {
    color: #666;
  }

  .wsmenu>.wsmenu-list>li>.wsmenu-click>i {
    margin: 22px 18px 0px 0px;
  }

  .wsmenu>.wsmenu-list>li>a>i {
    color: #fff;
  }

  .wsmenu>.wsmenu-list>li>ul.sub-menu {
    padding-left: 8px;
    padding-right: 8px;
  }

  .wsmenu>.wsmenu-list>li>ul.sub-menu>li>a,
  .wsmenu>.wsmenu-list>li>.wsmegamenu .link-list li a {
    color: #888;
    font-size: 15px;
    padding: 8px 8px;
  }

  .wsmenu>.wsmenu-list>li>.wsmegamenu.halfmenu {
    padding: 5px 0px 0 0px;
  }

  .wsmenu>.wsmenu-list>li>.wsmegamenu .link-list.left-link-list li:last-child {
    border-bottom: 1px dashed #c0c0c0;
  }

  .wsmenu>.wsmenu-list>li a.last-link-number {
    font-size: 17px;
    margin-left: 15px;
  }

  .wsmenu>.wsmenu-list>li a.last-link-number i {
    color: #666;
    margin-top: -0.5px;
  }

  .callusbtn {
    font-size: 22px;
    padding: 24px 20px;
  }

  .wsmenu>.wsmenu-list>li a.btn {
    height: 60px;
    font-size: 18px;
    line-height: 56px;
    padding: 0 17px;
    border: none;
    margin: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
  }

  .wsmenu>.wsmenu-list>li a.btn.btn-tra-white {
    background-color: #333;
    color: #fff;
  }

  .wsmobileheader .smllogo {
    margin-top: 10px;
  }

  .wsmobileheader .smllogo-black,
  .dark-menu .wsmobileheader .smllogo-white {
    display: block;
  }

  .wsmobileheader .smllogo-white,
  .dark-menu .wsmobileheader .smllogo-black {
    display: none;
  }

  /*------------------------------------------*/
  /*  HERO
  /*------------------------------------------*/

  .hero-section {
    margin-top: 80px;
  }

  /*------------------------------------------*/
  /*   CONTENT
  /*------------------------------------------*/


  /* Sidebar */
  #sidebar {
    padding-bottom: 20px;
  }

  /* Page Pagination */
  .page-pagination {
    padding-bottom: 80px;
  }




}




/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */

@media (min-width: 480px) and (max-width: 767.95px) {

  /*------------------------------------------*/
  /*   BASE
  /*------------------------------------------*/

  .wide-100 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .wide-90 {
    padding-top: 80px;
    padding-bottom: 70px;
  }

  .wide-80 {
    padding-top: 80px;
    padding-bottom: 60px;
  }

  .wide-70 {
    padding-top: 80px;
    padding-bottom: 50px;
  }

  .wide-60 {
    padding-top: 80px;
    padding-bottom: 40px;
  }

  .wide-50 {
    padding-top: 80px;
    padding-bottom: 30px;
  }

  .wide-40 {
    padding-top: 80px;
    padding-bottom: 20px;
  }

  .wide-30 {
    padding-top: 80px;
    padding-bottom: 10px;
  }

  .wide-20 {
    padding-top: 80px;
    padding-bottom: 0;
  }

  .pt-100,
  .division.pt-100 {
    padding-top: 80px;
  }

  .bg-scroll,
  .bg-01,
  .bg-02,
  .bg-03,
  .bg-04,
  .bg-map {
    background-attachment: scroll !important;
  }

  /* Header H5 */
  h5.h5-xs {
    font-size: 1.1rem;
  }

  h5.h5-sm {
    font-size: 1.15rem;
  }

  h5.h5-md {
    font-size: 1.2rem;
  }

  h5.h5-lg {
    font-size: 1.25rem;
  }

  h5.h5-xl {
    font-size: 1.35rem;
  }

  /* Header H4 */
  h4.h4-xs {
    font-size: 1.45rem;
  }

  h4.h4-sm {
    font-size: 1.55rem;
  }

  h4.h4-md {
    font-size: 1.65rem;
  }

  h4.h4-lg {
    font-size: 1.7rem;
  }

  h4.h4-xl {
    font-size: 1.8rem;
  }

  /* Header H3 */
  h3.h3-xs {
    font-size: 1.85rem;
  }

  h3.h3-sm {
    font-size: 1.9rem;
  }

  h3.h3-md {
    font-size: 1.95rem;
  }

  h3.h3-lg {
    font-size: 2rem;
  }

  h3.h3-xl {
    font-size: 2.1rem;
  }

  /* Header H2 */
  h2.h2-xs {
    font-size: 2.15rem;
  }

  h2.h2-sm {
    font-size: 2.25rem;
  }

  h2.h2-md {
    font-size: 2.35rem;
  }

  h2.h2-lg {
    font-size: 2.55rem;
  }

  h2.h2-xl {
    font-size: 2.75rem;
  }

  h2.h2-huge {
    font-size: 3.25rem;
  }

  /* Paragraphs */
  p.p-sm {
    font-size: 0.85rem;
  }

  p {
    font-size: 0.85rem;
  }

  p.p-md {
    font-size: 0.85rem;
  }

  p.p-lg {
    font-size: 0.85rem;
  }

  p.p-xl {
    font-size: 1.133rem;
  }

  /* List Settings */
  ul.txt-list li,
  ul.ico-list li {
    font-size: 0.85rem;
    margin-bottom: 5px;
  }

  .ico-list.mb-10 {
    margin-bottom: 15px;
  }

  ul.ico-list li i {
    font-size: 0.8rem;
    margin-top: 6px;
    margin-right: 8px;
  }

  .txt-list.mb-15 {
    margin-top: 10px;
  }

  /* Button */
  .btn {
    font-size: 15px;
    padding: 12px 24px;
  }

  .btn.btn-md {
    font-size: 16px;
    padding: 14px 28px;
  }

  /* Section Title */
  .section-title {
    margin-bottom: 40px;
    padding-right: 0;
  }

  .section-title.title-centered {
    padding: 0;
  }

  .section-title.title-centered p {
    padding: 0;
  }

  .section-title h4 {
    font-size: 1.8rem;
  }

  .section-title p {
    margin-top: 12px;
  }

  .title-btn {
    position: relative;
    right: 0;
    bottom: 0;
    margin-top: 20px;
  }

  /* Text Block Typography */
  .txt-block h3.h3-sm {
    margin-bottom: 20px;
  }

  .txt-block .btn {
    margin-top: 10px;
  }

  /*------------------------------------------*/
  /*   NAVIGATION MENU    
  /*------------------------------------------*/

  .headtoppart {
    display: none;
  }

  .wsmobileheader {
    height: 50px;
    background-color: #fff;
    box-shadow: 0 0 2px rgba(50, 50, 50, 0.4);
  }

  .dark-menu .wsmobileheader {
    height: 50px;
    background-color: #283034 !important;
    box-shadow: 0 0 2px rgba(50, 50, 50, 0.4);
  }

  .wsanimated-arrow {
    padding: 14px 28px 14px 0px;
    margin: 24px 0 0 20px;
  }

  .wsanimated-arrow span:before {
    top: -8px;
    width: 26px;
  }

  .wsanimated-arrow span:after {
    bottom: -8px;
    width: 20px;
  }

  .wsactive .wsanimated-arrow span:before,
  .wsactive .wsanimated-arrow.active span:after {
    width: 28px;
    top: 0px;
  }

  .wsactive .wsanimated-arrow span:after {
    width: 28px;
    top: 0;
  }

  .wsactive .wsanimated-arrow span {
    background: transparent;
  }

  .dark-menu .wsanimated-arrow span,
  .dark-menu .wsanimated-arrow span:after,
  .dark-menu .wsanimated-arrow span:before {
    background: #fff;
  }

  .dark-menu .callusbtn {
    color: #fff;
  }

  .wsactive .dark-menu .wsanimated-arrow span {
    background: transparent;
  }

  .wsmenu>.wsmenu-list>li>a {
    font-size: 15px;
    padding: 5px 32px 5px 18px;
    margin: 0;
  }

  .tra-menu .wsmenu>.wsmenu-list>li>a,
  .dark-menu .wsmenu>.wsmenu-list>li>a {
    color: #666;
  }

  .wsmenu>.wsmenu-list>li>.wsmenu-click>i {
    margin: 22px 18px 0px 0px;
  }

  .wsmenu>.wsmenu-list>li>a>i {
    color: #fff;
  }

  .wsmenu>.wsmenu-list>li>ul.sub-menu {
    padding-left: 8px;
    padding-right: 8px;
  }

  .wsmenu>.wsmenu-list>li>ul.sub-menu>li>a,
  .wsmenu>.wsmenu-list>li>.wsmegamenu .link-list li a {
    color: #888;
    font-size: 15px;
    padding: 14px 9px;
  }

  .wsmenu>.wsmenu-list>li>.wsmegamenu.halfmenu {
    padding: 5px 0px 0 0px;
  }

  .wsmenu>.wsmenu-list>li>.wsmegamenu .link-list.left-link-list li:last-child {
    border-bottom: 1px dashed #c0c0c0;
  }

  .wsmenu>.wsmenu-list>li a.last-link-number {
    font-size: 17px;
    margin-left: 15px;
  }

  .wsmenu>.wsmenu-list>li a.last-link-number i {
    color: #666;
    margin-top: -0.5px;
  }

  .callusbtn {
    font-size: 22px;
    padding: 24px 20px;
  }

  .wsmenu>.wsmenu-list>li a.btn {
    height: 60px;
    font-size: 18px;
    line-height: 56px;
    padding: 0 17px;
    border: none;
    margin: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
  }

  .wsmenu>.wsmenu-list>li a.btn.btn-tra-white {
    background-color: #333;
    color: #fff;
  }

  .wsmobileheader .smllogo {
    margin-top: 10px;
  }

  .wsmobileheader .smllogo-black,
  .dark-menu .wsmobileheader .smllogo-white {
    display: block;
  }

  .wsmobileheader .smllogo-white,
  .dark-menu .wsmobileheader .smllogo-black {
    display: none;
  }

  /*------------------------------------------*/
  /*  HERO
  /*------------------------------------------*/

  .hero-section {
    margin-top: 80px;
  }

  #hero-2 {
    text-align: center;
    padding-top: 90px;
    padding-bottom: 60px;
  }

  /* Hero Text */
  #hero-1 .caption-txt,

  /* Hero Slider */
  .slider,
  .slider .slides,

  .slider .slides li img {
    background-position: left;
  }

  /* Hero Headers */
  #hero-1 h2 {
    font-size: 2.75rem;
    margin-bottom: 20px;
  }

  #hero-2 h3 {
    font-size: 2.75rem;
    margin-bottom: 20px;
  }

  #hero-2 h5 {
    font-size: 1.45rem;
  }



  #hero-4 h2 {
    font-size: 3rem;
    padding: 0 8%;
    margin-bottom: 20px;
  }

  #hero-5 h2 {
    font-size: 2.5rem;
    padding: 0;
    margin-bottom: 20px;
  }

  /* Hero Paragraphs */
  #hero-1 .caption-txt p {
    font-size: 1.066rem;
    padding: 0 5%;
    margin-bottom: 30px;
  }

  #hero-2 .hero-txt p {
    padding: 0 5%;
    margin-bottom: 30px;
  }

  #hero-1 .hero-form {
    margin: 40px 10% 0;
  }

  /* Hero Image */
  .hero-2-img {
    display: none;
  }


  .all-courses-btn {
    margin-top: 15px;
  }


  #pricing-2 .pricing-table {
    padding: 30px 70px 45px;
    margin: 0 0 40px;
  }

  .pricing-plan h5 {
    font-size: 1rem;
  }

  #pricing-2 .pricing-plan h5 {
    font-size: 1.75rem;
  }

  .pricing-plan h4 {
    font-size: 1.15rem;
    margin-bottom: 25px;
  }

  ul.features li {
    font-size: 0.85rem;
    padding: 11px 0;
  }

  .pricing-plan p.p-sm {
    font-size: 1.25rem;
  }

  .pricing-table .btn {
    padding: 15px 26px;
  }

  .pricing-notice {
    margin-top: 15px;
  }

  /* FAQs */
  #faqs-1 .tabs-nav {
    margin-right: 0;
    margin-bottom: 50px;
  }

  #faqs-1 .tabs-1 li h5 {
    font-size: 1.35rem;
  }

  #faqs-1 .tabs-1 li p {
    font-size: 1.05rem;
  }

  .question h5 {
    font-size: 1.35rem;
  }

  /* Contacts */
  #contacts-2 {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .contacts-1-holder {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .contact-box {
    padding: 50px 20%;
  }

  .contact-box.b-right {
    border-right: none;
    border-bottom: 1px solid #ddd;
  }

  .contact-box h5 {
    font-size: 1.4rem;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .contact-box p {
    font-size: 1.1rem;
  }

  .contact-box img.img-75 {
    width: 70px;
    height: 70px;
  }

  /* Footer */
  .footer {
    padding-top: 80px;
    padding-bottom: 30px;
  }

  #footer-1 .footer-links,
  #footer-2 .footer-links,
  #footer-3 .footer-links {
    padding-left: 0;
  }

  .footer h5 {
    font-size: 1.2rem;
    margin-bottom: 22px;
  }

  .footer-box p,
  .footer-contacts p,
  .footer-links li {
    font-size: 1.05rem;
  }

  .bottom-footer .col-lg-4.text-right {
    text-align: left !important;
    margin-top: 10px;
  }

  .footer-form .btn {
    font-size: 17px;
  }

  /* Inner Page Wrapper */
  .inner-page-wrapper {
    margin-top: 80px;
  }

  /* Breadcrumb */
  #breadcrumb .col-md-12 {
    padding-left: 0;
    padding-right: 0;
  }

  .breadcrumb {
    padding: 15px 5px;
  }

  .breadcrumb-item a,
  .breadcrumb-item.active {
    font-size: 1rem;
  }

  .breadcrumb-item+.breadcrumb-item::before {
    font-size: 1.1rem;
    margin-top: -1px;
  }

  /* Page Hero */
  .page-hero-section {
    padding-top: 40px;
    padding-bottom: 38px;
  }

  /* Sidebar */
  #sidebar {
    padding-bottom: 20px;
  }

  /* Page Pagination */
  .page-pagination {
    padding-bottom: 80px;
  }

}




/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */

@media (min-width: 321px) and (max-width: 479.95px) {



  /*------------------------------------------*/
  /*   BASE
  /*------------------------------------------*/

  .wide-100 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .wide-90 {
    padding-top: 80px;
    padding-bottom: 70px;
  }

  .wide-80 {
    padding-top: 80px;
    padding-bottom: 60px;
  }

  .wide-70 {
    padding-top: 80px;
    padding-bottom: 50px;
  }

  .wide-60 {
    padding-top: 80px;
    padding-bottom: 40px;
  }

  .wide-50 {
    padding-top: 80px;
    padding-bottom: 30px;
  }

  .wide-40 {
    padding-top: 80px;
    padding-bottom: 20px;
  }

  .wide-30 {
    padding-top: 80px;
    padding-bottom: 10px;
  }

  .wide-20 {
    padding-top: 80px;
    padding-bottom: 0;
  }

  .pt-100,
  .division.pt-100 {
    padding-top: 80px;
  }

  .bg-scroll,
  .bg-01,
  .bg-02,
  .bg-03,
  .bg-04,
  .bg-map {
    background-attachment: scroll !important;
  }

  /* Header H5 */
  h5.h5-xs {
    font-size: 1.1rem;
  }

  h5.h5-sm {
    font-size: 1.15rem;
  }

  h5.h5-md {
    font-size: 1.2rem;
  }

  h5.h5-lg {
    font-size: 1.25rem;
  }

  h5.h5-xl {
    font-size: 1.35rem;
  }

  /* Header H4 */
  h4.h4-xs {
    font-size: 1.4rem;
  }

  h4.h4-sm {
    font-size: 1.45rem;
  }

  h4.h4-md {
    font-size: 1.5rem;
  }

  h4.h4-lg {
    font-size: 1.55rem;
  }

  h4.h4-xl {
    font-size: 1.6rem;
  }

  /* Header H3 */
  h3.h3-xs {
    font-size: 1.6rem;
  }

  h3.h3-sm {
    font-size: 1.65rem;
  }

  h3.h3-md {
    font-size: 1.7rem;
  }

  h3.h3-lg {
    font-size: 1.75rem;
  }

  h3.h3-xl {
    font-size: 1.8rem;
  }

  /* Header H2 */
  h2.h2-xs {
    font-size: 1.85rem;
  }

  h2.h2-sm {
    font-size: 2.15rem;
  }

  h2.h2-md {
    font-size: 2.35rem;
  }

  h2.h2-lg {
    font-size: 2.55rem;
  }

  h2.h2-xl {
    font-size: 2.75rem;
  }

  h2.h2-huge {
    font-size: 3.25rem;
  }

  /* Paragraphs */
  p.p-sm {
    font-size: 0.75rem;
  }

  p {
    font-size: 0.85rem;
  }

  p.p-md {
    font-size: 1rem;
  }


  /* List Settings */
  ul.txt-list li,
  ul.ico-list li {
    font-size: 0.85rem;
    margin-bottom: 5px;
  }

  ul.ico-list li {
    line-height: 1.4;
    margin-bottom: 10px;
  }

  .ico-list.mb-10 {
    margin-bottom: 15px;
  }

  ul.ico-list li i {
    display: inline-block;
    text-align: center;
    float: left;
    font-size: 0.7rem;
    margin-top: 6px;
    margin-right: 7px;
  }

  ul.ico-list li span {
    display: block;
    overflow: hidden;
  }

  .txt-list.mb-15 {
    margin-top: 10px;
  }

  /* Button */
  .btn {
    font-size: 15px;
    padding: 12px 24px;
  }

  .btn.btn-md {
    font-size: 16px;
    padding: 14px 28px;
  }

  /* Section Title */
  .section-title {
    margin-bottom: 40px;
    padding-right: 0;
  }

  .section-title.title-centered {
    padding: 0;
  }

  .section-title.title-centered p {
    padding: 0;
  }

  .section-title p {
    margin-top: 12px;
  }

  .title-btn {
    position: relative;
    right: 0;
    bottom: 0;
    margin-top: 25px;
  }

  /* Text Block Typography */
  .txt-block h3.h3-sm {
    margin-bottom: 20px;
  }

  .txt-block .btn {
    margin-top: 10px;
  }

  /*------------------------------------------*/
  /*  HERO
  /*------------------------------------------*/

  .hero-section {
    margin-top: 80px;
  }

  #hero-2 {
    text-align: center;
    padding-top: 90px;
    padding-bottom: 60px;
  }

  /* Hero Text */
  #hero-1 .caption-txt,

  /* Hero Slider */
  .slider,
  .slider .slides {
    text-align: center;
    margin-top: 0;
    height: 520px;
  }

  .slider .slides li img {
    background-position: left;
  }

  /* Hero Headers */
  #hero-1 h2 {
    font-size: 1.85rem;
    margin-bottom: 20px;
  }

  #hero-2 h3 {
    font-size: 2.25rem;
    margin-bottom: 20px;
  }

  #hero-2 h5 {
    font-size: 1.25rem;
  }

  /* Hero Paragraphs */
  #hero-1 .caption-txt p {
    font-size: 1.05rem;
    padding: 0;
    margin-bottom: 30px;
  }

  #hero-2 .hero-txt p {
    font-size: 1.05rem;
    padding: 0 3%;
    margin-bottom: 30px;
  }

  /* Hero Search Form */
  #hero-1 .hero-form {
    margin: 30px 2% 0;
  }

  .hero-form .form-control {
    height: 52px;
  }

  .hero-form .btn {
    height: 52px;
    font-size: 1.25rem;
  }

  /* Hero Image */
  .hero-2-img {
    display: none;
  }

  /*------------------------------------------*/
  /*   CONTENT
  /*------------------------------------------*/

  .division {
    padding-left: 3px;
    padding-right: 3px;
  }

  .hero-section.division,
  #contacts-1.division {
    padding-left: 0;
    padding-right: 0;
  }


  #pricing-2 .pricing-table {
    padding: 20px 20px 35px;
    margin: 0 0 40px;
  }

  .pricing-plan h5 {
    font-size: 1rem;
  }

  .pricing-plan h4 {
    font-size: 1.125rem;
    margin-bottom: 25px;
  }

  ul.features li {
    font-size: 0.85rem;
    padding: 11px 0;
  }

  .pricing-plan p.p-sm {
    font-size: 1.25rem;
  }

  .pricing-table .btn {
    padding: 15px 26px;
  }

  .pricing-notice p {
    padding: 0;
  }

  .pricing-txt h3 {
    font-size: 1.65rem;
    line-height: 1.3;
  }

  .pricing-notice {
    margin-top: 15px;
  }

  span.badge {
    margin-bottom: 8px;
    padding: 4px 5px;
    margin-right: 4px;
  }

  span.badge a {
    font-size: 0.915rem;
  }

  /* FAQs */
  #faqs-1 .tabs-nav {
    margin-right: 0;
    margin-bottom: 50px;
  }

  #faqs-1 .tabs-1 li {
    padding: 26px 20px;
  }

  #faqs-1 .tab-link img {
    display: none;
  }

  #faqs-1 .tab-link-txt {
    padding-left: 0;
  }

  #faqs-1 .tabs-1 li h5 {
    font-size: 1.35rem;
  }

  #faqs-1 .tabs-1 li p {
    font-size: 1rem;
  }

  .question h5 {
    font-size: 1.25rem;
  }

  /* Contacts */
  #contacts-2 {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .contacts-1-holder {
    padding-top: 50px;
    padding-bottom: 50px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
  }

  #contacts-1 .contacts-txt p {
    padding: 0 10%;
  }

  .contact-box {
    padding: 50px 10%;
  }

  .contact-box.b-right {
    border-right: none;
    border-bottom: 1px solid #ddd;
  }

  .contact-box h5 {
    font-size: 1.3rem;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .contact-box p {
    font-size: 1.05rem;
  }

  .contact-box img.img-75 {
    width: 70px;
    height: 70px;
  }

  /* Footer */
  .footer {
    padding-top: 80px;
    padding-bottom: 30px;
  }

  #footer-1 .footer-links,
  #footer-2 .footer-links,
  #footer-3 .footer-links {
    padding-left: 0;
  }

  .footer h5 {
    font-size: 1.2rem;
    margin-bottom: 22px;
  }

  .footer-box p,
  .footer-contacts p,
  .footer-links li {
    font-size: 1.05rem;
  }

  .bottom-footer .col-lg-4.text-right {
    text-align: left !important;
    margin-top: 10px;
  }

  p.footer-copyright {
    font-size: 0.975rem;
  }

  .footer-form .btn {
    font-size: 17px;
  }

  /* Inner Page Wrapper */
  .inner-page-wrapper {
    margin-top: 80px;
  }

  /* Breadcrumb */
  .breadcrumb {
    padding: 15px 5px;
  }

  .breadcrumb-item a,
  .breadcrumb-item.active {
    font-size: 1rem;
  }

  .breadcrumb-item+.breadcrumb-item::before {
    font-size: 1.1rem;
    margin-top: -1px;
  }

  /* Page Hero */
  .page-hero-section {
    padding-top: 40px;
    padding-bottom: 35px;
  }

  .page-hero-section h4,
  .page-hero-section h3 {
    margin-bottom: 20px;
  }

  .page-hero-section p {
    font-size: 1.05rem;
    line-height: 22px;
  }

  .page-hero-section .share-social-icons {
    text-align: left !important;
  }

  .share-social-icons li p {
    font-size: 1.05rem;
  }

  a.share-ico {
    font-size: 15px;
    line-height: 18px;
    margin-right: 10px;
  }

  /* Sidebar */
  #sidebar {
    padding-bottom: 20px;
  }

  ul.blog-category-list>li {
    padding: 18px 0;
  }

  ul.blog-category-list>li:first-child {
    padding: 0 0 18px;
  }

  ul.blog-category-list>li:last-child {
    padding: 18px 0 0;
  }

  ul.blog-category-list>li a {
    font-size: 1.05rem;
  }

  /* Page Pagination */
  .page-pagination {
    padding-bottom: 80px;
  }


  .what-you-learn {
    padding: 30px 20px;
  }

  .what-you-learn .txt-list li {
    font-size: 1rem;
  }

  .barWrapper {
    margin-bottom: 10px;
  }

  .progress-wrapper {
    width: 60%;
  }

  .card-body p.cb-video {
    margin-bottom: 4px;
  }


  .hdr-data {
    display: block;
    position: relative;
    top: 0;
    right: 0;
    margin-top: 5px;
  }

}




/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ */

@media (max-width: 320.95px) {

  /*------------------------------------------*/
  /*   BASE
  /*------------------------------------------*/

  .wide-100 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .wide-90 {
    padding-top: 80px;
    padding-bottom: 70px;
  }

  .wide-80 {
    padding-top: 80px;
    padding-bottom: 60px;
  }

  .wide-70 {
    padding-top: 80px;
    padding-bottom: 50px;
  }

  .wide-60 {
    padding-top: 80px;
    padding-bottom: 40px;
  }

  .wide-50 {
    padding-top: 80px;
    padding-bottom: 30px;
  }

  .wide-40 {
    padding-top: 80px;
    padding-bottom: 20px;
  }

  .wide-30 {
    padding-top: 80px;
    padding-bottom: 10px;
  }

  .wide-20 {
    padding-top: 80px;
    padding-bottom: 0;
  }

  .bg-scroll,
  .bg-01,
  .bg-02,
  .bg-03,
  .bg-04,
  .bg-map {
    background-attachment: scroll !important;
  }

  /* Header H5 */
  h5.h5-xs {
    font-size: 1.1rem;
  }

  h5.h5-sm {
    font-size: 1.15rem;
  }

  h5.h5-md {
    font-size: 1.2rem;
  }

  h5.h5-lg {
    font-size: 1.25rem;
  }

  h5.h5-xl {
    font-size: 1.35rem;
  }

  /* Header H4 */
  h4.h4-xs {
    font-size: 1.4rem;
  }

  h4.h4-sm {
    font-size: 1.45rem;
  }

  h4.h4-md {
    font-size: 1.5rem;
  }

  h4.h4-lg {
    font-size: 1.55rem;
  }

  h4.h4-xl {
    font-size: 1.6rem;
  }

  /* Header H3 */
  h3.h3-xs {
    font-size: 1.6rem;
  }

  h3.h3-sm {
    font-size: 1.65rem;
  }

  h3.h3-md {
    font-size: 1.7rem;
  }

  h3.h3-lg {
    font-size: 1.75rem;
  }

  h3.h3-xl {
    font-size: 1.8rem;
  }

  /* Header H2 */
  h2.h2-xs {
    font-size: 1.85rem;
  }

  h2.h2-sm {
    font-size: 2.15rem;
  }

  h2.h2-md {
    font-size: 2.35rem;
  }

  h2.h2-lg {
    font-size: 2.55rem;
  }

  h2.h2-xl {
    font-size: 2.75rem;
  }

  h2.h2-huge {
    font-size: 3.25rem;
  }

  /* Paragraphs */
  p.p-sm {
    font-size: 0.75rem;
  }

  p {
    font-size: 0.85rem;
  }

  p.p-md {
    font-size: 1rem;
  }


  /* List Settings */
  ul.txt-list li,
  ul.ico-list li {
    font-size: 0.85rem;
    margin-bottom: 5px;
  }

  ul.ico-list li {
    line-height: 1.4;
    margin-bottom: 10px;
  }

  .ico-list.mb-10 {
    margin-bottom: 15px;
  }

  ul.ico-list li i {
    display: inline-block;
    text-align: center;
    float: left;
    font-size: 0.7rem;
    margin-top: 6px;
    margin-right: 7px;
  }

  ul.ico-list li span {
    display: block;
    overflow: hidden;
  }

  .txt-list.mb-15 {
    margin-top: 10px;
  }

  /* Button */
  .btn {
    font-size: 15px;
    padding: 12px 24px;
  }

  .btn.btn-md {
    font-size: 16px;
    padding: 14px 28px;
  }

  /* Section Title */
  .section-title {
    margin-bottom: 40px;
    padding-right: 0;
  }

  #courses-4 .section-title {
    padding-right: 0;
  }

  .section-title.title-centered {
    padding: 0;
  }

  .section-title.title-centered p {
    padding: 0;
  }

  .section-title p {
    margin-top: 12px;
  }

  .title-btn {
    position: relative;
    right: 0;
    bottom: 0;
    margin-top: 25px;
  }

  /* Text Block Typography */
  .txt-block h3.h3-sm {
    margin-bottom: 20px;
  }

  .txt-block .btn {
    margin-top: 10px;
  }

  /*------------------------------------------*/
  /*  HERO
  /*------------------------------------------*/

  .hero-section {
    margin-top: 80px;
  }

  #hero-2 {
    text-align: center;
    padding-top: 90px;
    padding-bottom: 60px;
  }

  /* Hero Text */
  #hero-1 .caption-txt {
    margin-top: -30px;
  }

  /* Hero Slider */
  .slider,
  .slider .slides {
    text-align: center;
    margin-top: 0;
    height: 520px;
  }

  .slider .slides li img {
    background-position: left;
  }

  /* Hero Headers */
  #hero-1 h2 {
    font-size: 1.85rem;
    margin-bottom: 20px;
  }

  #hero-2 h3 {
    font-size: 2.25rem;
    margin-bottom: 20px;
  }

  /* Hero Paragraphs */
  #hero-1 .caption-txt p {
    font-size: 1.05rem;
    padding: 0;
    margin-bottom: 30px;
  }

  #hero-2 .hero-txt p {
    font-size: 1.05rem;
    padding: 0 3%;
    margin-bottom: 30px;
  }


  /* Hero Search Form */
  #hero-1 .hero-form {
    margin: 30px 2% 0;
  }

  .hero-form .form-control {
    height: 52px;
  }

  .hero-form .btn {
    height: 52px;
    font-size: 1.25rem;
  }

  /* Hero Image */
  .hero-2-img {
    display: none;
  }

  /*------------------------------------------*/
  /*   CONTENT
  /*------------------------------------------*/

  .division {
    padding-left: 3px;
    padding-right: 3px;
  }

  .hero-section.division,
  #news-1.division,
  #newsletter-1.division,
  #contacts-1.division {
    padding-left: 0;
    padding-right: 0;
  }

  #pricing-2 .pricing-table {
    padding: 20px 20px 35px;
    margin: 0 0 40px;
  }

  .pricing-plan h5 {
    font-size: 1rem;
  }

  .pricing-plan h4 {
    font-size: 1.125rem;
    margin-bottom: 25px;
  }

  ul.features li {
    font-size: 0.85rem;
    padding: 11px 0;
  }

  .pricing-plan p.p-sm {
    font-size: 1.25rem;
  }

  .pricing-table .btn {
    padding: 15px 26px;
  }

  .pricing-notice p {
    padding: 0;
  }

  .pricing-txt h3 {
    font-size: 1.65rem;
    line-height: 1.3;
  }

  .pricing-notice {
    margin-top: 15px;
  }

  /* FAQs */
  #faqs-1 .tabs-nav {
    margin-right: 0;
    margin-bottom: 50px;
  }

  #faqs-1 .tabs-1 li {
    padding: 26px 20px;
  }

  #faqs-1 .tab-link img {
    display: none;
  }

  #faqs-1 .tab-link-txt {
    padding-left: 0;
  }

  #faqs-1 .tabs-1 li h5 {
    font-size: 1.35rem;
  }

  #faqs-1 .tabs-1 li p {
    font-size: 1rem;
  }

  .question h5 {
    font-size: 1.25rem;
  }

  /* Contacts */
  #contacts-2 {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .contacts-1-holder {
    padding-top: 50px;
    padding-bottom: 50px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
  }

  #contacts-1 .contacts-txt p {
    padding: 0 10%;
  }

  .contact-box {
    padding: 50px 10%;
  }

  .contact-box.b-right {
    border-right: none;
    border-bottom: 1px solid #ddd;
  }

  .contact-box h5 {
    font-size: 1.3rem;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .contact-box p {
    font-size: 1.05rem;
  }

  .contact-box img.img-75 {
    width: 70px;
    height: 70px;
  }

  /* Footer */
  .footer {
    padding-top: 80px;
    padding-bottom: 30px;
  }

  #footer-1 .footer-links,
  #footer-2 .footer-links,
  #footer-3 .footer-links {
    padding-left: 0;
  }

  .footer h5 {
    font-size: 1.2rem;
    margin-bottom: 22px;
  }

  .footer-box p,
  .footer-contacts p,
  .footer-links li {
    font-size: 1.05rem;
  }

  .bottom-footer .col-lg-4.text-right {
    text-align: left !important;
    margin-top: 10px;
  }

  p.footer-copyright {
    font-size: 0.975rem;
  }

  .footer-form .btn {
    font-size: 17px;
  }

  /* Inner Page Wrapper */
  .inner-page-wrapper {
    margin-top: 80px;
  }

  /* Breadcrumb */
  .breadcrumb {
    padding: 15px 5px;
  }

  .breadcrumb-item a,
  .breadcrumb-item.active {
    font-size: 1rem;
  }

  .breadcrumb-item+.breadcrumb-item::before {
    font-size: 1.1rem;
    margin-top: -1px;
  }

  /* Page Hero */
  .page-hero-section {
    padding-top: 40px;
    padding-bottom: 35px;
  }

  .page-hero-section h4,
  .page-hero-section h3 {
    margin-bottom: 20px;
  }

  .page-hero-section p {
    font-size: 1.05rem;
    line-height: 22px;
  }

  .page-hero-section .share-social-icons {
    text-align: left !important;
  }

  .share-social-icons li p {
    font-size: 1.05rem;
  }

  a.share-ico {
    font-size: 15px;
    line-height: 18px;
    margin-right: 10px;
  }

  /* Sidebar */
  #sidebar {
    padding-bottom: 20px;
  }

  ul.blog-category-list>li {
    padding: 18px 0;
  }

  ul.blog-category-list>li:first-child {
    padding: 0 0 18px;
  }

  ul.blog-category-list>li:last-child {
    padding: 18px 0 0;
  }

  ul.blog-category-list>li a {
    font-size: 1.05rem;
  }

  /* Page Pagination */
  .page-pagination {
    padding-bottom: 80px;
  }

  .what-you-learn {
    padding: 30px 20px;
  }

  .what-you-learn .txt-list li {
    font-size: 1rem;
  }

  #accordion .card-body p,
  #accordion .card-body .txt-list li,
  p.download-file,
  p.download-file {
    font-size: 1.05rem;
  }

  .ratingtext-right {
    width: 20%;
  }

  .barWrapper {
    margin-bottom: 10px;
  }

  .progress-wrapper {
    width: 60%;
  }

  .card-body p.cb-video {
    margin-bottom: 4px;
  }

  p.download-file i {
    font-size: 0.975rem;
  }


  .review-4 {
    padding: 25px 20px;
  }

  .hdr-data {
    display: block;
    position: relative;
    top: 0;
    right: 0;
    margin-top: 5px;
  }





}